import axios, { AxiosResponse } from 'axios'
import { getNthEndpoint } from 'helper/getEndPoint'
import { baseUrl } from 'netwrok/baseUrl'
import endPoints from 'netwrok/endPoints'
import { checkCareerInterest } from 'store/helper/profile-module'
import { RootState } from '../configStore'
import constants from '../constants'
import {
    setEmail,
    setFirstName,
    setIsUserLoggedIn,
    setLastName,
} from './authActions'
type AddableItems =
    | 'education'
    | 'skills'
    | 'experience'
    | 'languages'
    | 'trainingCourses'
export const setHasNoExperince = (hasNoExperince: boolean) => {
    return {
        type: constants.SET_HAS_SUBMIT_EXPERIENCE,
        payload: hasNoExperince,
    }
}
export const setHasSubmitTC = (hasNoTC: boolean) => {
    return {
        type: constants.SET_HAS_SUBMIT_TC,
        payload: hasNoTC,
    }
}
export const setIsJobSelected = (jobId: number, value: boolean) => {
    return {
        type: constants.SET_IS_JOB_SELECTED,
        payload: { jobId, value },
    }
}
export const setIsDepartmentSelected = (
    departmentId: number,
    value: boolean
) => {
    return {
        type: constants.SET_IS_DEPARTMENT_SELECTED,
        payload: { departmentId, value },
    }
}
export const setShowProfile = (flag: boolean) => {
    return {
        type: constants.SET_SHOW_PROFILE,
        payload: flag,
    }
}
export const setDepartments = (department: any) => {
    return {
        type: constants.SET_DEPARTMENTS,
        payload: department,
    }
}
const setApplicationWasSubmittedModalText = (sentence: string) => {
    return {
        type: 'SET_applicationWasSubmittedModalText',
        payload: sentence,
    }
}
const setApplications = (applications: any) => {
    return {
        type: constants.SET_APPLICATIONS,
        payload: applications,
    }
}

const lang = localStorage.getItem('lang') as string

export const resetProfile = () => {
    return {
        type: constants.RESET_PROFILE,
    }
}
export const setAppWasSubmitted = (value: boolean) => {
    return {
        type: constants.SET_APP_WAS_SUBMITTED,
        payload: value,
    }
}
export const removeCareerItem = (name: AddableItems, index: any) => {
    return {
        type: constants.REMOVE_CAREER_ITEM,
        payload: { name, index },
    }
}
export const setDoneFlag = (endPoint: string, flag: boolean) => {
    return {
        type: constants.SET_DONE_FLAG,
        payload: { endPoint, flag },
    }
}
export const setCurrentJob = (currentJob: any) => {
    return {
        type: constants.SET_CURRENT_JOB,
        payload: { currentJob },
    }
}
export const setCareerCategory = (category: any, value: any) => {
    return {
        type: constants.SET_CAREER_CATEGORY,
        payload: { category, value },
    }
}
export const setCvPdf = (file: File) => {
    return {
        type: constants.SET_CV,
        payload: { file },
    }
}
export const setCareerInput = (category: string, input: string, value: any) => {
    return {
        type: constants.SET_CAREER_INPUT,
        payload: { category, input, value },
    }
}
export const setCvPdfSize = (size: string) => {
    return {
        type: constants.SET_CV_SIZE,
        payload: { size },
    }
}
export const setCareerInputOnAddableItem = (
    category: string,
    ind: number,
    input: string,
    value: any
) => {
    return {
        type: constants.SET_CAREER_INPUT_ON_ADDABLE_ITEM,
        payload: { category, ind, input, value },
    }
}

export const addCareerItem = (itemName: AddableItems) => {
    let item = {}
    switch (itemName) {
        case 'education':
            item = {
                education_level: '',
                specialisation: '',
                university: '',
                grade: '',
                start_month: '',
                start_year: 0,
                end_month: '',
                end_year: 0,
                status: 'create',
                isDateValid: false,
            }
            break
        case 'experience':
            item = {
                experience_type: '',
                job_title: '',
                company: '',
                salary: '',
                start_month: '',
                start_year: 0,
                end_month: '',
                end_year: 0,
                still_working: undefined,
                job_tasks: '',
                status: 'create',
                isDateValid: false,
            }
            break
        case 'trainingCourses':
            item = {
                course_name: '',
                training_center: '',
                start_month: '',
                start_year: 0,
                end_month: '',
                end_year: 0,
                description: '',
                status: 'create',
                isDateValid: false,
            }
            break
        case 'languages':
            item = {
                language_name: '',
                proficiency: '',
                status: 'create',
            }
            break
        case 'skills':
            item = {
                skill_name: '',
                status: 'create',
            }
            break

        default:
            break
    }
    return {
        type: constants.ADD_CAREER_ITEM,
        payload: { name: itemName, item: { ...item } },
    }
}
export const API_getProfile = (token?: string) => {
    return async (dispatch: React.Dispatch<any>) => {
        const savedToken = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Token ${token ? token : savedToken}`,
            },
        }
        if (savedToken !== null)
            try {
                const resDepartments = (await dispatch(
                    API_getCareerDepartmentsForUser(savedToken)
                )) as unknown as AxiosResponse<any, any>

                if (resDepartments.data.detail !== 'Invalid token') {
                    const res = await axios.get(endPoints.profile.all, config)
                    dispatch(setIsUserLoggedIn(true))
                    dispatch(setFirstName(res.data.first_name))
                    dispatch(setLastName(res.data.last_name))
                    dispatch(setEmail(res.data.username))

                    checkCareerInterest(res.data.career_interest, dispatch)
                    if (res.data.cv !== null) {
                        dispatch(
                            setCareerInput('cvPdf', 'path', res.data.cv.cv_file)
                        )
                        dispatch(
                            setCareerInput(
                                'cvPdf',
                                'name',
                                getNthEndpoint(res.data.cv.cv_file, -1)
                            )
                        )
                        dispatch(setDoneFlag('cv', true))
                    }
                    if (res.data.general_info !== null) {
                        const generalInformation = {
                            ...res.data.general_info,
                            profile_picture: {
                                img: res.data.general_info.profile_picture,
                            },
                            have_driving_license: res.data.general_info
                                .have_driving_license
                                ? 'Yes'
                                : 'No',
                        }
                        dispatch(
                            setCareerCategory(
                                'generalInformation',
                                generalInformation
                            )
                        )
                        dispatch(setDoneFlag('general-information', true))
                    }
                    if (res.data.educations.length !== 0) {
                        const educations: any[] = []
                        res.data.educations.forEach((educationItem: any) => {
                            educationItem.status = 'update'
                            educations.push(educationItem)
                        })
                        dispatch(setCareerCategory('education', educations))
                        dispatch(setDoneFlag('education', true))
                    }
                    if (res.data.experiences.length !== 0) {
                        const experiences: any[] = []
                        res.data.experiences.forEach((experinceItem: any) => {
                            experinceItem.status = 'update'
                            experiences.push(experinceItem)
                        })
                        dispatch(setCareerCategory('experience', experiences))
                        dispatch(setDoneFlag('experience', true))
                    }
                    dispatch(
                        setHasNoExperince(
                            res.data.has_submit_experience &&
                                res.data.experiences.length == 0
                        )
                    )
                    if (
                        res.data.has_submit_experience &&
                        res.data.experiences.length == 0
                    ) {
                        dispatch(setDoneFlag('experience', true))
                    }
                    if (res.data.skills.length !== 0) {
                        const skills: any[] = []
                        res.data.skills.forEach((skillItem: any) => {
                            skillItem.status = ''
                            skills.push(skillItem)
                        })
                        dispatch(setCareerCategory('skills', skills))
                    }
                    if (res.data.languages.length !== 0) {
                        const languages: any[] = []
                        res.data.languages.forEach((languageItem: any) => {
                            languageItem.status = 'update'
                            languages.push(languageItem)
                        })
                        dispatch(setCareerCategory('languages', languages))
                        dispatch(setDoneFlag('skills-and-languages', true))
                    }
                    dispatch(
                        setHasSubmitTC(
                            res.data.has_submit_training_courses &&
                                res.data.training_courses.length == 0
                        )
                    )
                    if (
                        res.data.has_submit_training_courses &&
                        res.data.training_courses.length == 0
                    ) {
                        dispatch(setDoneFlag('training-courses', true))
                    }

                    if (res.data.training_courses.length !== 0) {
                        const training_courses: any[] = []
                        res.data.training_courses.forEach(
                            (trainingCourseItem: any) => {
                                trainingCourseItem.status = 'update'
                                training_courses.push(trainingCourseItem)
                            }
                        )
                        dispatch(
                            setCareerCategory(
                                'trainingCourses',
                                training_courses
                            )
                        )
                        dispatch(setDoneFlag('training-courses', true))
                    }
                    if (res.data.other_questions !== null) {
                        const otherQuestions = {
                            ...res.data.other_questions,
                            has_worked_here: res.data.other_questions
                                .has_worked_here
                                ? 'Yes'
                                : 'No',
                            has_diseases: res.data.other_questions.has_diseases
                                ? 'Yes'
                                : 'No',
                            has_relatives: res.data.other_questions
                                .has_relatives
                                ? 'Yes'
                                : 'No',
                            insured_before: res.data.other_questions
                                .insured_before
                                ? 'Yes'
                                : 'No',
                            able_to_work_abroad: res.data.other_questions
                                .able_to_work_abroad
                                ? 'Yes'
                                : 'No',
                        }
                        dispatch(
                            setCareerCategory('otherQuestions', otherQuestions)
                        )
                        dispatch(setDoneFlag('other-questions', true))
                    }
                    return res
                }
            } catch (error: any) {
                // console.error('API_getProfile', error)
                if (error.response?.status === 401) {
                    localStorage.removeItem('token')
                }
                return error
            }
    }
}
export const API_postCV = (cv_file: FormData) => {
    return async (_: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
                'Content-Type': 'multipart/form-data',
            },
        }
        try {
            const res = await axios.post(
                endPoints.profile.postCv,
                cv_file,
                config
            )
            return res
        } catch (error: any) {
            // console.error('API_postCV', (error as any).response)
            return error.response
        }
    }
}
export const API_postApplyForJob = (id: number) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = (await axios.post(
                endPoints.help.applyForJob,
                {
                    career: id,
                },
                config
            )) as any
            dispatch(setApplicationWasSubmittedModalText(res.data.message))
            return res.data
        } catch (error: any) {
            // console.error('API_postApplyForJob', (error as any).response)
            dispatch(
                setApplicationWasSubmittedModalText(error.response.data.message)
            )
            return error.response
        }
    }
}
export const API_getDepartments = () => {
    return async () => {
        const config = {
            headers: {
                'Accept-Language': lang,
            },
        }
        try {
            const res = await axios.get(endPoints.companies.departments, config)
            return res.data
        } catch (error) {
            // console.error('API_getDepartments', (error as any).response)
            // return error
        }
    }
}

export const API_getUserInfo = () => {
    return async (_: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = await axios.get(endPoints.profile.all, config)
            // return res
        } catch (error) {
            // console.error('API_getUserInfo', (error as any).response)
            // return error
        }
    }
}
export const API_postGeneralInfo = (generalInfo: FormData) => {
    return async (_: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
                'Content-Type': 'multipart/form-data',
            },
        }
        try {
            const res = await axios.post(
                endPoints.profile.generalInfo,
                generalInfo,
                config
            )
            return res
        } catch (error: any) {
            return error.response
        }
    }
}
export const API_postOtherQuestions = (otherQuestions: {
    has_worked_here: boolean
    job_title: string
    location: string
    start_month: string
    start_year: number
    end_month: string
    end_year: number
    leaving_reason: string
    has_relatives: boolean
    relatives_name: string
    able_to_work_abroad: boolean
    favourite_locations: string
    has_diseases: boolean
    disease_name: string
    insured_before: boolean
    insured_in: string
}) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = await axios.post(
                endPoints.profile.otherQuestions,
                otherQuestions,
                config
            )
            return res
        } catch (error: any) {
            // console.error('API_postOtherQuestions', error.response)
            return error.response
        }
    }
}
export const API_postEducation = (
    education: {
        id?: number
        education_level: string
        specialisation: string
        university: string
        grade: string
        start_month: string
        start_year: number
        end_month: string
        end_year: number
        status: string
    }[]
) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = await axios.post(
                endPoints.profile.saveEducation,
                education,
                config
            )
            const educations: any[] = []
            res.data.educations.forEach((educationItem: any) => {
                educationItem.status = 'update'
                educationItem.isDateValid = true

                educations.push(educationItem)
            })
            dispatch(setCareerCategory('education', educations))
            dispatch(setDoneFlag('education', true))

            return res
        } catch (error: any) {
            // console.error('API_postEducation', (error as any).response)
            return error.response
        }
    }
}
export const API_postExperince = (
    experince: {
        id?: number
        experience_type: string
        job_title: string
        company: string
        salary: number
        start_month: string
        start_year: number
        end_month: string
        end_year: number
        still_working: true
        job_tasks: string
        status: string
    }[]
) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = await axios.post(
                endPoints.profile.saveExperience,
                experince,
                config
            )
            const experiences: any[] = []
            if (res.data.experiences.length > 0) {
                res.data.experiences.forEach((experinceItem: any) => {
                    experinceItem.status = 'update'
                    experinceItem.isDateValid = true
                    experiences.push(experinceItem)
                })
                dispatch(setCareerCategory('experience', experiences))
                dispatch(setDoneFlag('experience', true))
            }

            return res
        } catch (error: any) {
            // console.error('API_postExperince', (error as any).response.data)
            return error.response
        }
    }
}
export const API_postTrainingCourses = (
    trainingCourses: {
        id?: number
        experience_type: string
        job_title: string
        company: string
        salary: number
        start_month: string
        start_year: number
        end_month: string
        end_year: number
        still_working: true
        job_tasks: string
        status: string
    }[]
) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = await axios.post(
                endPoints.profile.saveTrainingCourse,
                trainingCourses,
                config
            )
            const training_courses: any[] = []
            if (res.data.training_courses.length !== 0) {
                res.data.training_courses.forEach((trainingCourseItem: any) => {
                    trainingCourseItem.status = 'update'
                    trainingCourseItem.isDateValid = true
                    training_courses.push(trainingCourseItem)
                })
                dispatch(setCareerCategory('trainingCourses', training_courses))
                dispatch(setDoneFlag('training-courses', true))
            }

            return res
        } catch (error: any) {
            // console.error('API_postTrainingCourses', error.response)
            return error.response
        }
    }
}
export const API_postSkills = (
    skills: {
        id?: number
        skill_name: string
        status: string
    }[]
) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = await axios.post(
                endPoints.profile.saveSkill,
                skills,
                config
            )
            const modifiedSkills: any[] = []
            res.data.skills.forEach((skillItem: any) => {
                skillItem.status = ''
                modifiedSkills.push(skillItem)
            })
            dispatch(setCareerCategory('skills', modifiedSkills))

            return res
        } catch (error: any) {
            // console.error('API_postSkills', (error as any).response)
            return error.response
        }
    }
}
export const API_postLangs = (
    Langs: {
        id?: number
        language_name: string
        proficiency: string
        status: string
    }[]
) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = await axios.post(
                endPoints.profile.saveLang,
                Langs,
                config
            )
            const languages: any[] = []
            res.data.languages.forEach((languageItem: any) => {
                languageItem.status = 'update'
                languages.push(languageItem)
            })
            dispatch(setCareerCategory('languages', languages))
            dispatch(setDoneFlag('skills-and-languages', true))
            return res
        } catch (error: any) {
            // console.error('API_postLangs', (error as any).response)
            return error.response
        }
    }
}
export const API_deleteLang = (id: string) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
            data: {
                id,
            },
        }
        try {
            const res = await axios.delete(endPoints.profile.deleteLang, config)
            return res
        } catch (error) {
            // console.error('API_deleteLang', (error as any).response)
            // return error
        }
    }
}
export const API_getCareerDepartmentsForUser = (token?: string) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${
                    token ? token : getState().authReducer.token
                }`,
                'Accept-Language': lang,
            },
        }
        try {
            const res = await axios.get(endPoints.profile.departments, config)

            const departments = res.data.map((department: any) => {
                department.selected = false
                department.jobs = department.jobs.map((job: any) => {
                    job.selected = false
                    return job
                })
                return department
            })
            dispatch(setDepartments(departments))
            return res
        } catch (error: any) {
            // console.error('API_getCareerDepartmentsForUser', error.response)
            return error.response
        }
    }
}

export const API_postCareerInterest = (data: {
    departments: number[]
    jobs: number[]
}) => {
    return async (_: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = await axios.post(
                endPoints.profile.saveCareerInterest,
                data,
                config
            )
            return res
        } catch (error: any) {
            // console.error('API_postCareerInterest', error.response)
            return error.response
        }
    }
}

export const API_deleteTrainingCourse = (id: string) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
            data: {
                id,
            },
        }
        try {
            const res = await axios.delete(
                endPoints.profile.deleteTrainingCourse,
                config
            )
            return res
        } catch (error) {
            // console.error('API_deleteTrainingCourse', (error as any).response)
            // return error
        }
    }
}
export const API_deleteEducation = (id: string) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
            data: {
                id,
            },
        }
        try {
            const res = await axios.delete(
                endPoints.profile.deleteEducation,
                config
            )
            return res
        } catch (error) {
            // console.error('API_deleteEducation', (error as any).response)
            // return error
        }
    }
}
export const API_deleteExperience = (id: string) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
            data: {
                id,
            },
        }
        try {
            const res = await axios.delete(
                endPoints.profile.deleteExperience,
                config
            )
            return res
        } catch (error) {
            // console.error('API_deleteExperience', (error as any).response)
            // return error
        }
    }
}
export const API_deleteSkill = (id: string) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
            data: {
                id,
            },
        }
        try {
            const res = await axios.delete(
                endPoints.profile.deleteSkill,
                config
            )
            return res
        } catch (error) {
            // console.error('API_deleteSkill', (error as any).response)
            // return error
        }
    }
}

export const API_getApplications = () => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        const config = {
            headers: {
                Authorization: `Token ${getState().authReducer.token}`,
            },
        }
        try {
            const res = await axios.get(endPoints.help.applications, config)
            dispatch(setApplications(res.data))
            // return res
        } catch (error) {
            // console.error('API_applications', (error as any).response)
        }
    }
}
