import axios from 'axios'
import { debounce, storeScrollAtHtmlAttribute } from 'helper/StoreScroll'
import { Dispatch } from 'react'
import { Lang } from '../../types/global'
import constants from '../constants'
import { setIsUserLoggedIn } from './authActions'

export const setLang = (lang: Lang) => {
    return {
        type: constants.SET_LANG,
        payload: lang,
    }
}

export const setWidth = (width: number) => {
    return {
        type: constants.SET_WIDTH,
        payload: width,
    }
}
export const initWidthListener = () => (dispatch: Dispatch<any>) => {
    window.addEventListener('resize', () => {
        dispatch(setWidth(window.innerWidth))
    })
}

export const removeWidthListener = () => (dispatch: Dispatch<any>) => {
    window.removeEventListener('resize', () => {
        dispatch(setWidth(window.innerWidth))
    })
}
export const initScrollSaving = () => () => {
    document.addEventListener('scroll', debounce(storeScrollAtHtmlAttribute), {
        passive: true,
    })
    storeScrollAtHtmlAttribute()
}

export const initLang = () => (dispatch: Dispatch<any>) => {
    const lang: Lang = localStorage.getItem('lang') as Lang

    if (lang) {
        dispatch(setLang(lang))
        document
            .querySelector('html')!
            .setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr')
        document.querySelector('html')!.setAttribute('lang', lang)
    } else {
        dispatch(setLang('en'))
        document.querySelector('html')!.setAttribute('dir', 'ltr')
        document.querySelector('html')!.setAttribute('lang', 'en')
    }
}

export const setAxiosConfig = () => async (dispatch: Dispatch<any>) => {
    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token')
                dispatch(setIsUserLoggedIn(false))
            }
            return Promise.reject(error)
        }
    )
}
