import images from '../assets/images'

export const arabicLetterFormat =
    /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/

export const englishLetterFormat = /^[a-zA-Z]+$/

export const siteMapData: {
    title: string
    items: { title: string; to: string }[]
}[] = [
    {
        title: 'About',
        items: [
            { title: 'About Us', to: '/about' },
            { title: 'News', to: '/news' },
            { title: 'Gallery', to: '/gallery' },
            { title: 'Resources', to: '/resources' },
        ],
    },
    {
        title: 'Companies',
        items: [
            { title: 'Mafaza', to: '' },
            { title: 'New Elsabeel', to: '' },
            { title: 'Hegazy Power', to: '' },
            { title: 'Elkenana', to: '' },
            { title: 'Eltoba', to: '' },
            { title: 'Fat Hens', to: '' },
        ],
    },
    // {
    //     title: 'Sectors',
    //     items: sectors,
    // },
    {
        title: 'Reach Us',
        items: [{ title: 'Contact Us', to: '/contact-us' }],
    },
    {
        title: 'Join Us',
        items: [{ title: 'Careers', to: '/careers' }],
    },
]

const aboutItems: any = [
    {
        title: 'Brief About Us',
        img: images.briefAboutUs,
        path: '/about#about-section1',
    },
    {
        title: 'How We Grew out',
        img: images.howWeGrewout,
        path: '/about#about-section1',
    },
    {
        title: 'Vision & Mission',
        img: images.visionMission,
        path: '/about#vision-mission',
    },
    { title: 'History', img: images.history, path: '/about#history' },
    { title: 'Partners', img: images.partners, path: '/about#partners' },
    {
        title: 'Certificates',
        img: images.certificates,
        path: '/about#certificates',
    },
]
