import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'

const Wrapper: React.FC = (props) => {
    const location = useLocation()
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    return <>{props.children}</>
}
export default Wrapper
