export default {
    SET_FEAT_NEWS: 'setFeatNews',
    SET_ABOUT: 'setAboutContent',
    SET_ALL_NEWS: 'setAllNews',
    SET_NUM_PAGES_NEWS: 'setNumPagesNews',
    SET_SECTORS: 'setSectors',
    SET_SINGLE_NEWS: 'setSingleNews',
    SET_LANG: 'setLang',
    SET_CAREERS: 'setCareers',
    SET_ACTIVE_COMPANY: 'setActiveCompany',
    SET_COMPANIES_LIST: 'setCompaniesList',
    SET_RESOURCES: 'setResources',
    SET_NUM_PAGES_RESOURCES: 'setNumPagesResources',
    SET_SINGLE_RESOURCES: 'setSingleResources',
    SET_GALLERIES: 'setGalleries',
    SET_GENERAL_LOCATIONS: 'setGeneralLocations',
    SET_TOKEN: 'setToken',
    SET_CAREER_INPUT: 'setCareerInput',
    SET_CV: 'setCvPdf',
    SET_CV_SIZE: 'setCvPdfSize',
    SET_CAREER_INPUT_ON_ADDABLE_ITEM: 'setCareerInputOnAddableItem',
    REMOVE_CAREER_ITEM: 'removeCareerItem',
    ADD_CAREER_ITEM: 'addCareerItem',
    SET_USER_IS_LOGGED_IN: 'setIsUserLoggedIn',
    SET_CURRENT_JOB: 'setCurrentJob',
    SET_FIRST_NAME: 'setFirstName',
    SET_LAST_NAME: 'setLastName',
    SET_EMAIL: 'setEmail',
    SET_CAREER_CATEGORY: 'setCareerCategory',
    RESET_PROFILE: 'resetProfile',
    SET_DONE_FLAG: 'setDoneFlag',
    SET_APPLICATIONS: 'setApplications',
    SET_SHOW_PROFILE: 'setShowProfile',
    SET_DEPARTMENTS: 'setDepartments',
    SET_IS_JOB_SELECTED: 'setIsJobSelected',
    SET_IS_DEPARTMENT_SELECTED: 'setIsDepartmentSelected',
    SET_CAREER_SEARCH_TERM: 'setCareerSearchTerm',
    SET_APP_WAS_SUBMITTED: 'setAppWasSubmitted',
    SET_HAS_SUBMIT_EXPERIENCE: 'setHasSubmitExperience',
    SET_HAS_SUBMIT_TC: 'setHasSubmitTC',
    SET_CAREER_NUM_OF_PAGES: 'setCareersNumOfPages,',
    SET_ACTIVE_COMPANY_ID: 'setActiveCompanyId',
    SET_IS_QUOTA_POSTED: 'setIsQoutaPosted',
    SET_IS_ERROR_AT_QUOTA: 'setIsErrorAtQuota',
    SET_LANDING_CONTENT: 'setLandingContent',
    SET_IS_CONTACT_US_POSTED: 'setIsContactUsPosted',
    SET_IS_ERROR_AT_CONTACT_US: 'setIsErrorAtContactUs',
    SET_isFlagForgetPasswordTokenShown: 'setIsFlagForgetPasswordTokenShown',
    SET_isForgetPasswordTokenValid: 'setIsForgetPasswordTokenValid',
    SET_WIDTH: 'setWidth',
}
