import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import images from '../../assets/images'
import { Dropdown, Image } from 'react-bootstrap'
import { HashLink, NavHashLink } from 'react-router-hash-link'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface Props {
    isAbout?: any
    item: any
    isCompany?: any
    onClick?: () => void
}

const NavListItem: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    return (
        <>
            {props.isAbout ? (
                <Dropdown.Item
                    as={NavHashLink}
                    to={props.item.path}
                    onClick={props.onClick}
                >
                    <div className="media">
                        <img src={props.item.img} alt="dropdown icons" />
                    </div>
                    {t(props.item.title)}
                </Dropdown.Item>
            ) : (
                <Dropdown.Item
                    as={Link}
                    to={
                        props.isCompany
                            ? `/companies/${props.item.id}`
                            : `/sectors?sector=${props.item.id}`
                    }
                    onClick={props.onClick}
                >
                    <div className="media">
                        <img
                            src={
                                props.isCompany
                                    ? props.item.logo
                                    : props.item.icons.active
                            }
                            className={props.isCompany ? '' : 'custom'}
                            alt="dropdown icons"
                        />
                    </div>
                    {props.isCompany ? (
                        <p>
                            {props.item.title}
                            <span>{props.item.subtitle}</span>
                        </p>
                    ) : (
                        props.item.title
                    )}
                </Dropdown.Item>
            )}
        </>
    )
}

export default NavListItem
