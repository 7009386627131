import React, { useEffect, useState, Suspense, lazy } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import Default from 'layout/default'
import loader from 'assets/images/HG.gif'
import {
    initLang,
    initScrollSaving,
    initWidthListener,
    removeWidthListener,
    setAxiosConfig,
} from 'store/actions/settingsActions'
import { initUserCred } from 'store/actions/authActions'

import {
    setCurrentJob,
    setShowProfile,
} from 'store/actions/careerApplicationActions'
import {
    API_getCompaniesList,
    API_getLandingContent,
    API_getSectors,
} from 'store/actions/contentActions'
import Wrapper from 'components/ScrollToTop'
// import Authentication from 'layout/authentication'
import ReactGA from 'react-ga4'
import { getNthEndpoint } from 'helper/getEndPoint'
import axios from 'axios'
import ProtectedElement from 'components/ProtectedRoute'
import { RootState } from 'store/configStore'
import { baseUrl } from 'netwrok/baseUrl'
import 'styles/main.scss'
axios.defaults.baseURL = baseUrl

const Authentication = lazy(() => import('layout/authentication'))

const About = lazy(() => import('pages/about'))
const ApplicationCv = lazy(() => import('pages/application-1-cv'))
const ApplicationGeneralQuestions = lazy(
    () => import('pages/application-2-general-questions')
)
const ApplicationEducation = lazy(() => import('pages/application-3-education'))
const ApplicationExperience = lazy(
    () => import('pages/application-4-experience')
)
const ApplicationSkillsAndLangs = lazy(
    () => import('pages/application-5-skills-and-languages')
)
const ApplicationTrainingCourses = lazy(
    () => import('pages/application-6-training-courses')
)
const ApplicationOtherQuestions = lazy(
    () => import('pages/application-7-other-questions')
)
const Careers = lazy(() => import('pages/careers'))
const CareersSubmitted = lazy(() => import('pages/careers-submitted'))
const Companies = lazy(() => import('pages/companies'))
const ContactUs = lazy(() => import('pages/contact-us'))
const Home = lazy(() => import('pages/landing'))
const News = lazy(() => import('pages/news'))
const Resources = lazy(() => import('pages/resources'))
const Sectors = lazy(() => import('pages/sectors'))
const SingleNews = lazy(() => import('pages/singleNews'))
const Gallery = lazy(() => import('pages/gallery'))
const SignIn = lazy(() => import('pages/sign-in'))
const SignUp = lazy(() => import('pages/sign-up'))
const SingleResources = lazy(() => import('pages/singleResource'))
const ApplicationCareerInterest = lazy(
    () => import('pages/application-0-career-interset')
)
const ForgotPassword = lazy(() => import('pages/forgot-password'))
const ResetPassword = lazy(() => import('pages/reset-password'))

// import ApplicationCareerInterest from 'pages/application-0-career-interset'
// import ForgotPassword from 'pages/forgot-password'
// import ResetPassword from 'pages/reset-password'
// import About from 'pages/about'
// import ApplicationCv from 'pages/application-1-cv'
// import ApplicationGeneralQuestions from 'pages/application-2-general-questions'
// import ApplicationEducation from 'pages/application-3-education'
// import ApplicationExperience from 'pages/application-4-experience'
// import ApplicationSkillsAndLangs from 'pages/application-5-skills-and-languages'
// import ApplicationTrainingCourses from 'pages/application-6-training-courses'
// import ApplicationOtherQuestions from 'pages/application-7-other-questions'
// import Careers from 'pages/careers'
// import CareersSubmitted from 'pages/careers-submitted'
// import Companies from 'pages/companies'
// import ContactUs from 'pages/contact-us'
// import Home from 'pages/landing'
// import News from 'pages/news'
// import Resources from 'pages/resources'
// import Sectors from 'pages/sectors'
// import SingleNews from 'pages/singleNews'
// import Gallery from 'pages/gallery'
// import SignIn from 'pages/sign-in'
// import SignUp from 'pages/sign-up'

// import SingleResources from 'pages/singleResource'

const App: React.FC = () => {
    const dispatch = useDispatch()
    const loc = useLocation()
    const [loadingLandingPage, setLoadingLandingPage] = useState(true)
    const [prevEndpoint, setPrevEndpoint] = useState<string | undefined>(
        undefined
    )
    const { isLoggedIn } = useSelector((state: RootState) => state.authReducer)
    const initLoadingAtLanding = () => {
        setLoadingLandingPage(true)
        setTimeout(() => {
            setLoadingLandingPage(false)
        }, 3200)
    }
    const getLandingRes = async () => {
        // setLoadingLandingPage(true)
        initLoadingAtLanding()
        await dispatch(API_getLandingContent())
    }
    const removeCurrJobFromLocalStorage = () => {
        if (
            prevEndpoint == 'application' &&
            getNthEndpoint(loc.pathname, 1) !== 'application'
        ) {
            localStorage.removeItem('curretnJob')
        }
        setPrevEndpoint(getNthEndpoint(loc.pathname, 1))
    }

    const initCurrJob = () => {
        const currJob = localStorage.getItem('curretnJob')
        if (currJob && currJob !== 'undefined')
            dispatch(setCurrentJob(JSON.parse(currJob)))
        const showProfile = localStorage.getItem('showProfile')
        if (showProfile)
            switch (showProfile) {
                case 'true':
                    dispatch(setShowProfile(true))
                    break
                case 'false':
                    dispatch(setShowProfile(false))
                    break
            }
    }
    const getCompaniesAndSectors = async () => {
        await Promise.allSettled([
            dispatch(API_getCompaniesList()),
            dispatch(API_getSectors()),
        ])
    }
    useEffect(() => {
        dispatch(setAxiosConfig())
        getLandingRes()
        initCurrJob()
        getCompaniesAndSectors()
        dispatch(initLang())
        dispatch(initUserCred())
        dispatch(initScrollSaving())
        dispatch(initWidthListener())
        return () => {
            dispatch(removeWidthListener())
        }
    }, [])

    useEffect(() => {
        ReactGA.initialize('G-QGMHX32LCF')
        ReactGA.send({ hitType: 'pageview', page: loc.pathname })
        removeCurrJobFromLocalStorage()
    }, [loc])
    return (
        <Wrapper>
            <Suspense
                fallback={
                    <div className="loading-container">
                        <img src={loader} alt="loading indicator" />
                    </div>
                }
            >
                <Routes>
                    <Route
                        path="/"
                        element={
                            loadingLandingPage ? (
                                <div className="loading-container">
                                    <img src={loader} alt="loading indicator" />
                                </div>
                            ) : (
                                <Default staticNav>
                                    <Home />
                                </Default>
                            )
                        }
                    />
                    <Route
                        path="/about"
                        element={
                            <Default>
                                <About />
                            </Default>
                        }
                    />
                    <Route
                        path="/sectors"
                        element={
                            <Default>
                                <Sectors />
                            </Default>
                        }
                    />
                    <Route
                        path="/companies/:companyId"
                        element={
                            <Default>
                                <Companies />
                            </Default>
                        }
                    />
                    <Route
                        path="/news"
                        element={
                            <Default>
                                <News />
                            </Default>
                        }
                    />
                    <Route
                        path="/news/:newsId"
                        element={
                            <Default>
                                <SingleNews />
                            </Default>
                        }
                    />
                    <Route
                        path="/contact-us"
                        element={
                            <Default>
                                <ContactUs />
                            </Default>
                        }
                    />
                    <Route
                        path="/careers"
                        element={
                            <Default>
                                <Careers />
                            </Default>
                        }
                    />
                    <Route
                        path="/resources"
                        element={
                            <Default>
                                <Resources />
                            </Default>
                        }
                    />
                    <Route
                        path="/resources/:resourceId"
                        element={
                            <Default>
                                <SingleResources />
                            </Default>
                        }
                    />
                    <Route
                        path="/application/cv"
                        element={
                            <ProtectedElement
                                flag={isLoggedIn as boolean}
                                pathAlt="/sign-in"
                            >
                                <Default>
                                    <ApplicationCv />
                                </Default>
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path="/application/general-information"
                        element={
                            <ProtectedElement
                                flag={isLoggedIn as boolean}
                                pathAlt="/sign-in"
                            >
                                <Default>
                                    <ApplicationGeneralQuestions />
                                </Default>
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path="/application/education"
                        element={
                            <ProtectedElement
                                flag={isLoggedIn as boolean}
                                pathAlt="/sign-in"
                            >
                                <Default>
                                    <ApplicationEducation />
                                </Default>
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path="/application/experience"
                        element={
                            <ProtectedElement
                                flag={isLoggedIn as boolean}
                                pathAlt="/sign-in"
                            >
                                <Default>
                                    <ApplicationExperience />
                                </Default>
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path="/application/skills-and-languages"
                        element={
                            <ProtectedElement
                                flag={isLoggedIn as boolean}
                                pathAlt="/sign-in"
                            >
                                <Default>
                                    <ApplicationSkillsAndLangs />
                                </Default>
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path="/application/training-courses"
                        element={
                            <ProtectedElement
                                flag={isLoggedIn as boolean}
                                pathAlt="/sign-in"
                            >
                                <Default>
                                    <ApplicationTrainingCourses />
                                </Default>
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path="/application/other-questions"
                        element={
                            <ProtectedElement
                                flag={isLoggedIn as boolean}
                                pathAlt="/sign-in"
                            >
                                <Default>
                                    <ApplicationOtherQuestions />
                                </Default>
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path="/application/career-interest"
                        element={
                            <ProtectedElement
                                flag={isLoggedIn as boolean}
                                pathAlt="/sign-in"
                            >
                                <Default>
                                    <ApplicationCareerInterest />
                                </Default>
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path="/application/submitted"
                        element={
                            <ProtectedElement
                                flag={isLoggedIn as boolean}
                                pathAlt="/sign-in"
                            >
                                <Default>
                                    <CareersSubmitted />
                                </Default>
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path="/gallery"
                        element={
                            <Default>
                                <Gallery />
                            </Default>
                        }
                    />
                    <Route
                        path="/sign-in/*"
                        element={
                            <Authentication>
                                <SignIn />
                            </Authentication>
                        }
                    />
                    <Route
                        path="/sign-up"
                        element={
                            <Authentication>
                                <SignUp />
                            </Authentication>
                        }
                    />
                    <Route
                        path="/forgot-password"
                        element={
                            <Authentication>
                                <ForgotPassword />
                            </Authentication>
                        }
                    />
                    <Route
                        path="/reset-password/*"
                        element={
                            <Authentication>
                                <ResetPassword />
                            </Authentication>
                        }
                    />
                </Routes>
            </Suspense>
        </Wrapper>
    )
}

export default App
