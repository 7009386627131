import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import images from '../../assets/images'
import { Dropdown, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/configStore'
import { setShowProfile } from 'store/actions/careerApplicationActions'

interface Props {
    logOut: (value: any) => void
}

const AvatarDropdown: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const { isLoggedIn } = useSelector((state: RootState) => state.authReducer)
    const { firstName, lastName, email } = useSelector(
        (state: RootState) => state.authReducer
    )
    const dispatch = useDispatch()
    return (
        <>
            {isLoggedIn ? (
                <Dropdown id="account-avatar">
                    <Dropdown.Toggle>
                        <Image src={images.avatar} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <div className="account-info">
                            <div className="account-img">
                                <Image src={images.avatar} alt="" />
                            </div>
                            <div className="account-details">
                                <h5>{firstName + ' ' + lastName}</h5>
                                <h6>{email}</h6>
                            </div>
                        </div>
                        <hr />
                        <NavLink
                            onClick={() => {
                                dispatch(setShowProfile(true))
                                localStorage.setItem('showProfile', 'true')
                            }}
                            to="/application/career-interest"
                        >
                            {t('Profile')}
                        </NavLink>
                        <NavLink to="/application/submitted">
                            {t('Submitted Applications')}
                        </NavLink>
                        <Dropdown.Item
                            onClick={props.logOut}
                            className="logout-btn"
                        >
                            <Image src={images.logout} alt="" />
                            {t('Logout')}
                            <Image src={images.arrowType2.dark} alt="" />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ) : null}
        </>
    )
}

export default AvatarDropdown
