import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
    children: any
    flag: boolean
    pathAlt: string
}
const ProtectedElement: FC<Props> = (props) => {
    const navigate = useNavigate()
    useEffect(() => {
        if (props.flag === false) {
            navigate(props.pathAlt, { replace: true })
        }
    }, [props.flag])
    return props.children
}

export default ProtectedElement
