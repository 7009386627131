// The debounce function receives our function as a parameter
export const debounce = (fn: () => void): (() => void) => {
    // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    let frame: number
    // The debounce function returns a new function that can receive a variable number of arguments
    return (...params: []) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
            cancelAnimationFrame(frame)
        }
        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
            // Call our function and pass any params we received
            fn(...params)
        })
    }
}

export const getScrollPosition: () => number = () => {
    return window.scrollY
}
// Reads out the scroll position and stores it in the data attribute
// so we can use it in our stylesheets
export const storeScrollAtHtmlAttribute = () => {
    document.documentElement.dataset.scroll = String(getScrollPosition())
}
