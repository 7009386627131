import constants from '../constants'

let initialState = {
    showApplicationWasSubmittedModal: false,
    applicationWasSubmittedModalText: '',
    isProfileShown: false,
    applicationNavigation: [
        {
            endPoint: 'career-interest',
            title: 'career interest',
            doneFlag: false,
        },
        { endPoint: 'cv', title: 'upload CV', doneFlag: false },
        {
            endPoint: 'general-information',
            title: 'general information',
            doneFlag: false,
        },
        { endPoint: 'education', title: 'education', doneFlag: false },
        { endPoint: 'experience', title: 'experience', doneFlag: false },
        {
            endPoint: 'skills-and-languages',
            title: 'skills & languages',
            doneFlag: false,
        },
        {
            endPoint: 'training-courses',
            title: 'training courses',
            doneFlag: false,
        },
        {
            endPoint: 'other-questions',
            title: 'other questions',
            doneFlag: false,
        },
    ],
    currentJob: {},
    departments: [],
    cvPdf: {
        file: undefined,
        size: '',
        path: '',
        name: '',
    },
    generalInformation: {
        profile_picture: {},
        first_name: '',
        last_name: '',
        birth_date: '',
        gender: undefined,
        nationality: undefined,
        military_status: undefined,
        marital_status: undefined,
        children_num: 0,
        government: undefined,
        city: undefined,
        town: undefined,
        have_driving_license: undefined,
        driving_license_type: undefined,
        moblie_number: '',
        alternative_number: '',
        is_valid_first_name: false,
        is_valid_last_name: false,
        is_valid_mobile_number: false,
        is_valid_alternative_number: false,
    },
    education: [
        {
            education_level: '',
            specialisation: '',
            university: '',
            grade: '',
            start_month: '',
            start_year: 0,
            end_month: '',
            end_year: 0,
            status: 'create',
            isDateValid: false,
        },
    ],
    experience: [
        {
            experience_type: '',
            job_title: '',
            company: '',
            salary: '',
            start_month: '',
            start_year: 0,
            end_month: '',
            end_year: 0,
            still_working: undefined,
            job_tasks: '',
            status: 'create',
            isDateValid: false,
        },
    ],
    skills: [],
    applications: [],
    trainingCourses: [
        {
            course_name: '',
            training_center: '',
            start_month: '',
            start_year: 0,
            end_month: '',
            end_year: 0,
            description: '',
            status: 'create',
            isDateValid: false,
        },
    ],
    languages: [
        {
            language_name: '',
            proficiency: '',
            status: 'create',
        },
    ],
    otherQuestions: {
        has_worked_here: undefined,
        job_title: '',
        location: '',
        start_month: '',
        start_year: 0,
        end_month: '',
        end_year: 0,
        leaving_reason: '',
        has_relatives: undefined,
        relatives_name: '',
        able_to_work_abroad: undefined,
        favourite_locations: '',
        has_diseases: undefined,
        disease_name: '',
        insured_before: undefined,
        insured_in: '',
        isDateValid: false,
    },
    hasNoExperince: false,
    hasNoTrainingCourse: false,
}
const careerApplicationReducer = (
    state = initialState as any,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case 'SET_applicationWasSubmittedModalText':
            return {
                ...state,
                applicationWasSubmittedModalText: action.payload,
            }
        case constants.SET_HAS_SUBMIT_EXPERIENCE:
            return {
                ...state,
                hasNoExperince: action.payload,
            }
        case constants.SET_HAS_SUBMIT_TC:
            return {
                ...state,
                hasNoTrainingCourse: action.payload,
            }

        case constants.SET_APP_WAS_SUBMITTED:
            return {
                ...state,
                showApplicationWasSubmittedModal: action.payload,
            }
        case constants.SET_IS_JOB_SELECTED:
            return {
                ...state,
                departments: state.departments.map((department: any) => {
                    department.jobs.map((job: any) => {
                        if (job.id === action.payload.jobId) {
                            job.selected = action.payload.value
                        }
                        return job
                    })
                    return department
                }),
            }

        case constants.SET_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload,
            }
        case constants.SET_IS_DEPARTMENT_SELECTED:
            return {
                ...state,
                departments: state.departments.map(
                    (department: any, index: number) => {
                        if (department.id === action.payload.departmentId) {
                            department.selected = action.payload.value
                        }
                        return department
                    }
                ),
            }
        case constants.SET_SHOW_PROFILE:
            return {
                ...state,
                isProfileShown: action.payload,
            }
        case constants.SET_DONE_FLAG:
            const applicationNavigation = [...state.applicationNavigation]
            const elementIndex = state.applicationNavigation.filter(
                (ele: any) => ele.endPoint == action.payload.endPoint
            )[0]
            const index = state.applicationNavigation.findIndex(
                (ele: any) => ele.endPoint == action.payload.endPoint
            )
            elementIndex.doneFlag = action.payload.flag
            applicationNavigation[index] = elementIndex

            return {
                ...state,
                applicationNavigation,
            }
        case constants.SET_CURRENT_JOB:
            return {
                ...state,
                currentJob: action.payload.currentJob,
            }
        case constants.SET_CV_SIZE:
            return {
                ...state,
                cvPdf: { ...state.cvPdf, size: action.payload.size },
            }
        case constants.SET_CV:
            return {
                ...state,
                cvPdf: { ...state.cvPdf, file: action.payload.file },
            }
        case constants.SET_CAREER_CATEGORY:
            return {
                ...state,
                [action.payload.category]: action.payload.value,
            }
        case constants.SET_CAREER_INPUT:
            return {
                ...state,
                [action.payload.category]: {
                    ...state[action.payload.category],
                    [action.payload.input]: action.payload.value,
                },
            }
        case constants.ADD_CAREER_ITEM:
            return {
                ...state,
                [action.payload.name]: [
                    ...state[action.payload.name],
                    action.payload.item,
                ],
            }
        case constants.REMOVE_CAREER_ITEM:
            return {
                ...state,
                [action.payload.name]: state[action.payload.name].filter(
                    (ele: any, ind: any) => {
                        if (ind !== action.payload.index) {
                            return ele
                        }
                    }
                ),
            }
        case constants.SET_CAREER_INPUT_ON_ADDABLE_ITEM:
            state[action.payload.category].forEach((element: any, ind: any) => {
                if (ind == action.payload.ind)
                    element[action.payload.input] = action.payload.value
            })
            return {
                ...state,
                [action.payload.category]: [...state[action.payload.category]],
            }
        case constants.RESET_PROFILE:
            return {
                ...initialState,
                currentJob: state.currentJob,
            }

        case constants.SET_APPLICATIONS:
            return {
                ...state,
                applications: action.payload,
            }
        default:
            return state
    }
}
export default careerApplicationReducer
