import React from 'react'
import { Dots } from 'react-activity'
import { useTranslation } from 'react-i18next'

interface ItemsComponentProp {
    onClick: () => void
    title: string
    disabled?: boolean
    className?: string
    loading?: boolean
    done?: boolean
    image?: any
}

const BtnGreen: React.FC<ItemsComponentProp> = (props) => {
    const { t } = useTranslation()
    return (
        <button
            disabled={props.disabled}
            className={`btn-cust green-btn ${
                props.disabled ? 'opacity-50 not-allowed' : ''
            }`}
            onClick={props.onClick}
        >
            {props.loading ? (
                <>
                    {t('Sending')} <Dots />
                </>
            ) : (
                t(props.title)
            )}
            {props.image && <img src={props.image} alt="arrow" />}
        </button>
    )
}

export default BtnGreen
