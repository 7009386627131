export default {
    translation: {
        'Copyrights Reserved C HG Group 2022':
            'Copyrights Reserved C HG Group 2022',
        Poultry: 'Poultry',
        'Hatcheries & Farm Equipment': 'Hatcheries & Farm Equipment',
        'Feed & Feed Additives': 'Feed & Feed Additives',
        Agriculture: 'Agriculture',
        Brands: 'Brands',
        'Business Solutions': 'Business Solutions',
        'Share Article': 'Share Article',
        'Latest News': 'Latest News',
        العربية: 'العربية',

        cv: 'Upload CV',
    },
}
