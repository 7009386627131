import { createStore, combineReducers, applyMiddleware } from 'redux'

import authReducer from './reducers/authReducer'
import contentReducer from './reducers/contentReducer'
import settingsReducer from './reducers/settingsReducer'
import careerApplicationReducer from './reducers/careerApplicationReducer'
import ReduxThunk from 'redux-thunk'

const rootReducer = combineReducers({
    authReducer,
    contentReducer,
    settingsReducer,
    careerApplicationReducer,
})

const configureStore = () => {
    return createStore(rootReducer, applyMiddleware(ReduxThunk))
}

export type RootState = ReturnType<typeof rootReducer>
export const store = configureStore()
export default configureStore
