import axios, { AxiosResponse } from 'axios'
import React from 'react'
import { RootState } from 'store/configStore'
import { baseUrl } from 'netwrok/baseUrl'
import endPoints from 'netwrok/endPoints'
import { SemiNews } from 'types/content'
import { ResFilter } from 'types/resources'
import constants from '../constants'
import { ApiSectorState } from 'types/sector.modal'

const setActiveCompany = (activeCompanyDetails: {}) => {
    return {
        type: constants.SET_ACTIVE_COMPANY,
        payload: activeCompanyDetails,
    }
}
const setLandingContent = (landingContent: any) => {
    return {
        type: constants.SET_LANDING_CONTENT,
        payload: landingContent,
    }
}
const setCompaniesList = (companiesList: []) => {
    return {
        type: constants.SET_COMPANIES_LIST,
        payload: companiesList,
    }
}
const setFeatNews = (featNews: SemiNews[]) => {
    return {
        type: constants.SET_FEAT_NEWS,
        payload: featNews,
    }
}
const setFilteredNews = (featNews: SemiNews[]) => {
    return {
        type: constants.SET_ALL_NEWS,
        payload: featNews,
    }
}
const setSectors = (sectors: ApiSectorState[]) => {
    return {
        type: constants.SET_SECTORS,
        payload: sectors,
    }
}
const setResources = (resources: any) => {
    return {
        type: constants.SET_RESOURCES,
        payload: resources,
    }
}

const setCareers = (careers: any[]) => {
    return {
        type: constants.SET_CAREERS,
        payload: [...careers],
    }
}
const setCareersNumOfPages = (numOfPages: number) => {
    return {
        type: constants.SET_CAREER_NUM_OF_PAGES,
        payload: numOfPages,
    }
}
const setNumPagesNews = (pages: number) => {
    return {
        type: constants.SET_NUM_PAGES_NEWS,
        payload: pages,
    }
}
export const setActiveCompanyId = (id: number) => {
    return {
        type: constants.SET_ACTIVE_COMPANY_ID,
        payload: id,
    }
}
export const setIsQoutaPosted = (isQuotaPosted: boolean) => {
    return {
        type: constants.SET_IS_QUOTA_POSTED,
        payload: isQuotaPosted,
    }
}
export const setIsContactUsPosted = (isContactUsPosted: boolean) => {
    return {
        type: constants.SET_IS_CONTACT_US_POSTED,
        payload: isContactUsPosted,
    }
}
export const setIsErrorAtContactUs = (isErrorAtContactUs: boolean) => {
    return {
        type: constants.SET_IS_ERROR_AT_CONTACT_US,
        payload: isErrorAtContactUs,
    }
}

const setNumPagesResources = (pages: number) => {
    return {
        type: constants.SET_NUM_PAGES_RESOURCES,
        payload: pages,
    }
}
const setSingleNews = (singleNews: SemiNews) => {
    return {
        type: constants.SET_SINGLE_NEWS,
        payload: singleNews,
    }
}

const setGalleries = (galleries: any) => {
    return {
        type: constants.SET_GALLERIES,
        payload: galleries,
    }
}
const setGeneralLocations = (locations: any) => {
    return {
        type: constants.SET_GENERAL_LOCATIONS,
        payload: locations,
    }
}

const setSingleResources = (singleResources: any) => {
    return {
        type: constants.SET_SINGLE_RESOURCES,
        payload: singleResources,
    }
}
const setAboutContent = (partner: any) => {
    return {
        type: constants.SET_ABOUT,
        payload: partner,
    }
}
export const setCareerSearchTerm = (searchTerm: string) => {
    return {
        type: constants.SET_CAREER_SEARCH_TERM,
        payload: searchTerm,
    }
}
const setIsErrorAtQuota = (isErrorAtQuota: boolean) => {
    return {
        type: constants.SET_IS_ERROR_AT_QUOTA,
        payload: isErrorAtQuota,
    }
}
const lang = localStorage.getItem('lang') as string

const config = {
    headers: {
        'Accept-Language': lang,
    },
}
export const API_getFeatNews = () => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.get(endPoints.content.featNews, config)
            dispatch(setFeatNews(res.data))
            // return res
        } catch (error: any) {
            // console.error('API_getFeatNews ===>>>', (error as any).response)
            return error.response
        }
    }
}

export const API_getFilteredNews = (filter = {}, page = 1) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.post(
                endPoints.content.news + `?page=${page}`,
                filter,
                config
            )
            dispatch(setFilteredNews(res.data.results.results))
            const page_size = res.data.results.page_size
            dispatch(setNumPagesNews(Math.ceil(res.data.count / page_size)))
            // return res
        } catch (error: any) {
            // console.error('API_getFilteredNews', (error as any).response)
            return error.response
        }
    }
}

export const API_getSingleNews = (id: number) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.get(
                endPoints.content.singleNews + id,
                config
            )
            dispatch(setSingleNews(res.data))
            // return res
        } catch (error: any) {
            return error.response
        }
    }
}

export const API_getSingleResources = (id: number) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = (await axios.get(
                endPoints.content.singleResources + id,
                config
            )) as AxiosResponse<any>
            dispatch(setSingleResources(res.data))
        } catch (error) { }
    }
}

export const API_getSectors = () => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = (await axios.get(
                endPoints.companies.sectorsFullDetails,
                config
            )) as AxiosResponse<ApiSectorState[]>
            dispatch(setSectors(res.data))
            // return res
        } catch (error) {
            // console.error('API_getSectors', error)
            // return error
        }
    }
}

export const API_getAboutContent = () => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.get(endPoints.about.main, config)
            dispatch(setAboutContent(res.data))
            // return res
        } catch (error) {
            // console.error('API_getAboutContent', error)
            // return error
        }
    }
}
export const API_postQuota = (inquryObj: { company: number }) => {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
        try {
            inquryObj.company = getState().contentReducer.ActiveCompanyId
            const res = (await axios.post(
                `/companies/${getState().contentReducer.ActiveCompanyId}/quote`,
                inquryObj,
                config
            )) as AxiosResponse<any>
            if (res.status === 200 || res.status === 201) {
                dispatch(setIsQoutaPosted(true))
                dispatch(setIsErrorAtQuota(false))
            } else {
                dispatch(setIsQoutaPosted(true))
                dispatch(setIsErrorAtQuota(true))
            }
            // return res
        } catch (error: any) {
            dispatch(setIsQoutaPosted(true))
            dispatch(setIsErrorAtQuota(true))
            return error.response
        }
    }
}

export const API_postInquiry = (inquryObj: {}) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = (await axios.post(
                endPoints.help.contactUs,
                inquryObj,
                config
            )) as AxiosResponse<any>
            if (res.status === 200 || res.status === 201) {
                dispatch(setIsContactUsPosted(true))
                dispatch(setIsErrorAtContactUs(false))
            } else {
                dispatch(setIsContactUsPosted(true))
                dispatch(setIsErrorAtContactUs(true))
            }
        } catch (error: any) {
            dispatch(setIsContactUsPosted(true))
            dispatch(setIsErrorAtContactUs(true))
            return error.response
        }
    }
}
export const API_getLandingContent = () => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.get(endPoints.content.landingPage, config)
            dispatch(setLandingContent(res.data))
            // return res
        } catch (error: any) {
            // console.error('API_getLandingContent', error)
            return error.response
        }
    }
}

export const API_getCareers = (filter = {}, pageNumber: number = 1) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.post(
                endPoints.help.career + `?page=${pageNumber}`,
                filter,
                config
            )
            dispatch(setCareers(res.data.results.results))
            dispatch(
                setCareersNumOfPages(
                    Math.ceil(res.data.count / res.data.results.page_size)
                )
            )
            return res.data
        } catch (error: any) {
            // console.error('API_getCareers', error)
            return error.response
        }
    }
}

export const API_getCompanies = (id: string) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.get(
                endPoints.companies.companies + id,
                config
            )
            dispatch(setActiveCompany(res.data))
            // return res
        } catch (error) {
            // console.error('API_getCompanies', error)
        }
    }
}
export const API_getCompaniesList = () => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.get(endPoints.companies.companies, config)
            dispatch(setCompaniesList(res.data))
            // return res
        } catch (error) {
            // console.error('API_getCompanies', error)
        }
    }
}
export const API_getResources = (
    filter: ResFilter = { type: null, sector: null },
    page = 1
) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.post(
                endPoints.content.resources + `?page=${page}`,
                filter,
                config
            )
            dispatch(setResources(res.data.results.results))
            const page_size = res.data.results.page_size
            dispatch(
                setNumPagesResources(Math.ceil(res.data.count / page_size))
            )
        } catch (error) {
            // console.error('API_getResources', error)
        }
    }
}
export const API_getGalleries = () => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.get(endPoints.content.galleries, config)
            dispatch(setGalleries(res.data))
        } catch (error: any) {
            // console.error('API_getGalleries', error.response)
        }
    }
}

export const API_getLocations = () => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios.get(endPoints.companies.locations, config)
            dispatch(setGeneralLocations(res.data))
        } catch (error) {
            // console.error('locations', error)
        }
    }
}
