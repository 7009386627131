import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import images from 'assets/images'
import { getScrollPosition } from 'helper/StoreScroll'
import SectionFooter from './sections/SectionFooter'
import { useDispatch, useSelector } from 'react-redux'
import AvatarDropdown from './components/AvatarDropdown'
import { RootState } from 'store/configStore'
import { Dispatch } from 'redux'
import { Navbar, Container, Nav } from 'react-bootstrap'
import BtnGreen from 'components/BtnGreen'
import { setShowProfile } from 'store/actions/careerApplicationActions'
import CustomDropdown from './components/CustomDropdown'
import { API_postSignOut } from 'store/actions/authActions'
import { getNthEndpoint } from 'helper/getEndPoint'

const aboutItems: any = [
    {
        title: 'Brief About Us',
        img: images.briefAboutUs,
        path: '/about#about-HG',
    },
    {
        title: 'How We Grew Out',
        img: images.howWeGrewout,
        path: '/about#HG-expansions',
    },
    {
        title: 'Vision & Mission',
        img: images.visionMission,
        path: '/about#vision-mission',
    },
    { title: 'History', img: images.history, path: '/about#history' },
    { title: 'Partners', img: images.partners, path: '/about#partners' },
    {
        title: 'Certificates',
        img: images.certificates,
        path: '/about#certificates',
    },
]
interface Props {
    staticNav?: boolean
}
const Default: React.FC<Props> = ({ children, staticNav = false }) => {
    const { t } = useTranslation()
    const loc = useLocation()
    const navigate = useNavigate()
    const dispatch: Dispatch<any> = useDispatch()

    const { isLoggedIn } = useSelector((state: RootState) => state.authReducer)
    const { width } = useSelector((state: RootState) => state.settingsReducer)
    const { companiesList, sectors } = useSelector(
        (state: RootState) => state.contentReducer
    )

    const changeLang = () => {
        const oldLang = document.querySelector('html')?.lang
        const newLang = oldLang !== 'ar' ? 'ar' : 'en'
        document.querySelector('html')!.setAttribute('lang', newLang)
        document
            .querySelector('html')!
            .setAttribute('dir', newLang == 'ar' ? 'rtl' : 'ltr')
        localStorage.setItem('lang', newLang)
        i18next.changeLanguage(newLang, (err, t) => {
            if (err) return console.error('something went wrong loading', err)
            t('key') // -> same as i18next.t
        })
        window.location.reload()
    }
    const isCareerPage = () => {
        return getNthEndpoint(loc.pathname, 1) === 'careers'
    }

    const logOut = async () => {
        const res = await dispatch(API_postSignOut())
        localStorage.removeItem('token')
        localStorage.setItem('showProfile', 'true')
        if (getNthEndpoint(loc.pathname, 1) === 'application') {
            navigate('/sign-in')
        }
    }
    const navbarToggler = document.getElementsByClassName(
        'navbar-toggler'
    )[0] as HTMLButtonElement
    const hideMenu = () => {
        navbarToggler.click()
        // navbarToggler.onclick()
    }
    return (
        <>
            <Navbar expand="lg" className={staticNav ? `static-nav` : ''}>
                <Container fluid>
                    <NavLink to="/" className={'navbar-brand'}>
                        <img
                            src="https://hegazy-website.s3.eu-west-2.amazonaws.com/media/static/logo.png"
                            alt="HG Logo"
                        />
                    </NavLink>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="" navbarScroll>
                            <CustomDropdown
                                title={t('About Us')}
                                data={aboutItems}
                                isAbout={true}
                                endpoint="about"
                                hideToggle={hideMenu}
                                width={width}
                            />
                            <CustomDropdown
                                title={t('Sectors')}
                                data={sectors}
                                endpoint="sectors"
                                hideToggle={hideMenu}
                                width={width}
                            />

                            <CustomDropdown
                                title={t('Companies')}
                                data={companiesList}
                                isCompany={true}
                                endpoint="companies"
                                hideToggle={hideMenu}
                                width={width}
                            />

                            <NavLink
                                to="/news"
                                className="nav-link"
                                onClick={width < 992 ? hideMenu : () => {}}
                            >
                                {t('News')}
                            </NavLink>
                            <NavLink
                                to="/resources"
                                className="nav-link"
                                onClick={width < 992 ? hideMenu : () => {}}
                            >
                                {t('Resources')}
                            </NavLink>
                            <NavLink
                                to="/gallery"
                                className="nav-link"
                                onClick={width < 992 ? hideMenu : () => {}}
                            >
                                {t('Gallery')}
                            </NavLink>
                            <NavLink
                                to="/careers"
                                className="nav-link"
                                onClick={width < 992 ? hideMenu : () => {}}
                            >
                                {t('Careers')}
                            </NavLink>
                            <NavLink
                                to="/contact-us"
                                className="nav-link"
                                onClick={width < 992 ? hideMenu : () => {}}
                            >
                                {t('Contact Us')}
                            </NavLink>
                            <Nav.Link
                                href="#"
                                onClick={changeLang}
                                id="change-lang"
                                className="nav-link"
                            >
                                {t('العربية')}
                            </Nav.Link>

                            {width >= 992 ? (
                                isLoggedIn ? (
                                    <AvatarDropdown logOut={logOut} />
                                ) : isCareerPage() ? (
                                    <BtnGreen
                                        title={t('Join Now')}
                                        onClick={() => {
                                            dispatch(setShowProfile(true))
                                            localStorage.setItem(
                                                'showProfile',
                                                'true'
                                            )
                                            navigate('/sign-in')
                                        }}
                                    />
                                ) : null
                            ) : isLoggedIn ? (
                                <>
                                    <NavLink
                                        onClick={() => {
                                            dispatch(setShowProfile(true))
                                            localStorage.setItem(
                                                'showProfile',
                                                'true'
                                            )
                                            hideMenu()
                                        }}
                                        to="/application/career-interest"
                                        className="nav-link"
                                    >
                                        {t('Profile')}
                                    </NavLink>
                                    <NavLink
                                        to="/application/submitted"
                                        className="nav-link"
                                        onClick={hideMenu}
                                    >
                                        {t('Submitted Applications')}
                                    </NavLink>
                                    <NavLink
                                        onClick={logOut}
                                        className="logout-btn nav-link"
                                        to="/sign-in"
                                    >
                                        {t('Logout')}
                                        <img
                                            src={images.arrowType2.white}
                                            alt="arrow"
                                        />
                                    </NavLink>
                                </>
                            ) : (
                                <NavLink
                                    className="nav-link"
                                    to="/sign-in"
                                    onClick={() => {
                                        dispatch(setShowProfile(true))
                                        localStorage.setItem(
                                            'showProfile',
                                            'true'
                                        )
                                        navigate('/sign-in')
                                    }}
                                >
                                    {t('Join Now')}
                                </NavLink>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {children}
            <SectionFooter />
        </>
    )
}

export default Default
