import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configStore from './store/configStore'
import './localization/i18n'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from './localization/i18n'

const store = configStore()
const lang = localStorage.getItem('lang') as string

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: resources,
        lng: lang,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    })

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)

reportWebVitals()
