import { getNthEndpoint } from 'helper/getEndPoint'
import {
    setCareerCategory,
    setCareerInput,
    setDoneFlag,
    setHasNoExperince,
    setHasSubmitTC,
    setIsDepartmentSelected,
    setIsJobSelected,
} from 'store/actions/careerApplicationActions'
export const checkCareerInterest = (
    career_interest: any,
    dispatch: (fun: any) => void
) => {
    if (career_interest == null) {
        dispatch(setDoneFlag('career-interest', false))
    } else {
        career_interest?.departments.forEach((department: any) => {
            dispatch(setIsDepartmentSelected(department.id, true))
        })
        career_interest?.jobs.forEach((job: any) => {
            dispatch(setIsJobSelected(job.id, true))
        })
        dispatch(setDoneFlag('career-interest', true))
    }
}

export const checkCv = (cv: any, dispatch: (fun: any) => void) => {
    if (cv !== null) {
        dispatch(setCareerInput('cvPdf', 'path', cv.cv_file))
        dispatch(
            setCareerInput('cvPdf', 'name', getNthEndpoint(cv.cv_file, -1))
        )
        dispatch(setDoneFlag('cv', true))
    }
}

export const checkOtherQuestions = (
    other_questions: any,
    dispatch: (fun: any) => void
) => {
    if (other_questions !== null) {
        const otherQuestions = {
            ...other_questions,
            has_worked_here: other_questions.has_worked_here ? 'Yes' : 'No',
            has_diseases: other_questions.has_diseases ? 'Yes' : 'No',
            has_relatives: other_questions.has_relatives ? 'Yes' : 'No',
            insured_before: other_questions.insured_before ? 'Yes' : 'No',
            able_to_work_abroad: other_questions.able_to_work_abroad
                ? 'Yes'
                : 'No',
        }
        dispatch(setCareerCategory('otherQuestions', otherQuestions))
        dispatch(setDoneFlag('other-questions', true))
    }
}

export const checkGeneralInfo = (
    general_info: any,
    dispatch: (fun: any) => void
) => {
    if (general_info !== null) {
        const generalInformation = {
            ...general_info,
            profile_picture: {
                img: general_info.profile_picture,
            },
            have_driving_license: general_info.have_driving_license
                ? 'Yes'
                : 'No',
        }
        dispatch(setCareerCategory('generalInformation', generalInformation))
        dispatch(setDoneFlag('general-information', true))
    }
}
export const checkEducations = (
    educations: any,
    dispatch: (fun: any) => void
) => {
    if (educations.length !== 0) {
        const educations: any[] = []
        educations.forEach((educationItem: any) => {
            educationItem.status = 'update'
            educations.push(educationItem)
        })
        dispatch(setCareerCategory('education', educations))
        dispatch(setDoneFlag('education', true))
    }
}
export const checkExperince = (
    experiences: any,
    has_submit_experience: any,
    dispatch: (fun: any) => void
) => {
    if (experiences.length !== 0) {
        const experiences: any[] = []
        experiences.forEach((experinceItem: any) => {
            experinceItem.status = 'update'
            experiences.push(experinceItem)
        })
        dispatch(setCareerCategory('experience', experiences))
        dispatch(setDoneFlag('experience', true))
    }
    dispatch(
        setHasNoExperince(has_submit_experience && experiences.length == 0)
    )
    if (has_submit_experience && experiences.length == 0) {
        dispatch(setDoneFlag('experience', true))
    }
}

export const checkSkillsAndLangs = (
    skills: any,
    languages: any,
    dispatch: (fun: any) => void
) => {
    if (skills.length !== 0) {
        const skills: any[] = []
        skills.forEach((skillItem: any) => {
            skillItem.status = ''
            skills.push(skillItem)
        })
        dispatch(setCareerCategory('skills', skills))
    }
    if (languages.length !== 0) {
        const languages: any[] = []
        languages.forEach((languageItem: any) => {
            languageItem.status = 'update'
            languages.push(languageItem)
        })
        dispatch(setCareerCategory('languages', languages))
        dispatch(setDoneFlag('skills-and-languages', true))
    }
}

export const checkTC = (
    training_courses: any,
    has_submit_training_courses: boolean,
    dispatch: (fun: any) => void
) => {
    dispatch(
        setHasSubmitTC(
            has_submit_training_courses && training_courses.length == 0
        )
    )
    if (has_submit_training_courses && training_courses.length == 0) {
        dispatch(setDoneFlag('training-courses', true))
    }

    if (training_courses.length !== 0) {
        const training_courses: any[] = []
        training_courses.forEach((trainingCourseItem: any) => {
            trainingCourseItem.status = 'update'
            training_courses.push(trainingCourseItem)
        })
        dispatch(setCareerCategory('trainingCourses', training_courses))
        dispatch(setDoneFlag('training-courses', true))
    }
}

// checkCareerInterest(res.data.career_interest , dispatch)
// checkCv(res.data.cv,dispatch)
// checkGeneralInfo(res.data.general_info,dispatch)
// checkEducations(res.data.educations,dispatch)
// checkExperince(res.data.experiences,res.data.has_submit_experience ,dispatch)
// checkSkillsAndLangs(res.data.skills,res.data.languages , dispatch)
// checkTC(res.data.training_courses ,res.data.has_submit_training_courses,dispatch)
// checkOtherQuestions(res.data.other_questions , dispatch)
