export default {
    auth: {
        register: '/auth/register',
        logout: '/auth/logout',
        login: '/auth/login',
        changePassword: '/auth/changePassword',
        checkEmail: '/auth/checkEmail',
        checkEmailAndSendLink: '/auth/checkEmailAndSendLink',
        checkForgetPasswordToken: '/auth/checkForgetPasswordToken',
        resetPassword: '/auth/resetPassword',
        checkConfirmMailToken: '/auth/checkConfirmMailToken',
        resendConfirmMail: '/auth/resendConfirmMail',
        googleAuth: '/auth/google_oauth',
    },
    profile: {
        all: '/profile/',
        postCv: '/profile/cv/save/',
        departments: '/profile/career-interest/',
        saveCareerInterest: '/profile/career-interest/save/',
        saveEducation: '/profile/education/save/',
        saveExperience: '/profile/experience/save/',
        generalInfo: '/profile/general-info/save/',
        deleteEducation: '/profile/education/delete/',
        otherQuestions: '/profile/other-questions/save/',
        saveTrainingCourse: '/profile/training-course/save/',
        deleteTrainingCourse: '/profile/training-course/delete/',
        saveSkill: '/profile/skill/save/',
        deleteSkill: '/profile/skill/delete/',
        saveLang: '/profile/language/save/',
        deleteLang: '/profile/language/delete/',
        deleteExperience: '/profile/experience/delete/',
    },
    content: {
        landingPage: '/content/landingPage',
        resources: '/content/resources',
        singleResources: '/content/resources/single/',
        news: '/content/news',
        singleNews: '/content/news/single/',
        featNews: '/content/news/featured',
        partners: '/content/partners',
        statistics: '/content/statistics',
        galleries: '/content/galleries/',
    },
    companies: {
        companies: '/companies/',
        sectorsList: '/companies/sectors/all',
        sectorsFullDetails: '/companies/sectors',
        departments: '/companies/departments',
        locations: '/companies/general-locations',
    },
    about: {
        main: '/content/about',
    },
    help: {
        applyForJob: '/help/careers/apply',
        contactUs: '/help/contactUs',
        career: '/help/careers',
        applications: '/help/applications',
    },
}
