import constants from '../constants'

let initialState: {
    token: string
    isLoggedIn: boolean | undefined
    firstName: string
    lastName: string
    email: string
    isForgetPasswordTokenValid: boolean
    isFlagForgetPasswordTokenShown: boolean
} = {
    token: '',
    isLoggedIn: undefined,
    firstName: '',
    lastName: '',
    email: '',
    isForgetPasswordTokenValid: false,
    isFlagForgetPasswordTokenShown: false,
}

const authReducer = (
    state = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case constants.SET_isForgetPasswordTokenValid:
            return {
                ...state,
                isForgetPasswordTokenValid: action.payload,
            }

        case constants.SET_isFlagForgetPasswordTokenShown:
            return {
                ...state,
                isFlagForgetPasswordTokenShown: action.payload,
            }

        case constants.SET_EMAIL:
            return {
                ...state,
                email: action.payload,
            }
        case constants.SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.payload,
            }
        case constants.SET_LAST_NAME:
            return {
                ...state,
                lastName: action.payload,
            }
        case constants.SET_USER_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.payload,
            }
        case constants.SET_TOKEN:
            return {
                ...state,
                token: action.payload,
            }

        default:
            return state
    }
}
export default authReducer
