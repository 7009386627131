import axios, { AxiosResponse } from 'axios'
import { Dispatch } from 'react'
import endPoints from '../../netwrok/endPoints'
import constants from '../constants'
import { RootState } from '../configStore'
import {
    API_getProfile,
    resetProfile,
    setDoneFlag,
} from './careerApplicationActions'

export const setFirstName = (fName: string) => {
    return {
        type: constants.SET_FIRST_NAME,
        payload: fName,
    }
}
export const setLastName = (lName: string) => {
    return {
        type: constants.SET_LAST_NAME,
        payload: lName,
    }
}
export const setEmail = (email: string) => {
    return {
        type: constants.SET_EMAIL,
        payload: email,
    }
}

export const setToken = (token: string) => {
    return {
        type: constants.SET_TOKEN,
        payload: token,
    }
}

export const setIsUserLoggedIn = (isLoggedIn: boolean) => {
    return {
        type: constants.SET_USER_IS_LOGGED_IN,
        payload: isLoggedIn,
    }
}

export const setIsFlagForgetPasswordTokenShown = (flag: boolean) => {
    return {
        type: constants.SET_isFlagForgetPasswordTokenShown,
        payload: flag,
    }
}
export const setIsForgetPasswordTokenValid = (flag: boolean) => {
    return {
        type: constants.SET_isForgetPasswordTokenValid,
        payload: flag,
    }
}

export const API_postRegister = (data: {
    email: string
    password: string
    first_name: string
    last_name: string
}) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const res = await axios.post(endPoints.auth.register, data)
            return res.data
        } catch (error) {
            // console.error('API_postRegister', (error as any).response)
            return (error as any).response
        }
    }
}

export const API_postSignIn = (data: {
    username: string
    password: string
}) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const res = await axios.post(endPoints.auth.login, data)
            dispatch(setIsUserLoggedIn(true))
            dispatch(setToken(res.data.token))
            localStorage.setItem('token', res.data.token)
            return res
        } catch (error) {
            // console.error('API_postSignIn', (error as any).response)
            return (error as any).response
        }
    }
}
export const API_postSignOut = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            const config = {
                headers: {
                    Authorization: `Token ${getState().authReducer.token}`,
                },
            }
            const res = await axios.post(endPoints.auth.logout, {}, config)
            if (res.data.status_code == 200) {
                dispatch(setIsUserLoggedIn(false))
                dispatch(resetProfile())
                dispatch(setDoneFlag('cv', false))
                dispatch(setDoneFlag('general-information', false))
                dispatch(setDoneFlag('education', false))
                dispatch(setDoneFlag('experience', false))
                dispatch(setDoneFlag('skills-and-languages', false))
                dispatch(setDoneFlag('training-courses', false))
                dispatch(setDoneFlag('other-questions', false))
            }
            return res
        } catch (error: any) {
            dispatch(setIsUserLoggedIn(false))
            dispatch(resetProfile())
            dispatch(setDoneFlag('cv', false))
            dispatch(setDoneFlag('general-information', false))
            dispatch(setDoneFlag('education', false))
            dispatch(setDoneFlag('experience', false))
            dispatch(setDoneFlag('skills-and-languages', false))
            dispatch(setDoneFlag('training-courses', false))
            dispatch(setDoneFlag('other-questions', false))
            // console.error('API_postSignOut', error.response)
            return error.response
        }
    }
}

export const API_postPotentialRegisteredMail = (data: { email: string }) => {
    return async (_: Dispatch<any>) => {
        try {
            const res = await axios.post(endPoints.auth.checkEmail, data)
            return res
        } catch (error) {
            // console.error(
            //     'API_postPotentialRegisteredMail',
            //     (error as any).response
            // )
            return (error as any).response
        }
    }
}

export const API_checkEmailAndSendLink = (data: { email: string }) => {
    return async (_: Dispatch<any>) => {
        try {
            const res = await axios.post(
                endPoints.auth.checkEmailAndSendLink,
                data
            )
            return res
        } catch (error) {
            // console.error('API_checkEmailAndSendLink', (error as any).response)
            return (error as any).response.data
        }
    }
}

export const API_checkForgetPasswordToken = (data: { token: string }) => {
    return async (_: Dispatch<any>) => {
        try {
            const res = await axios.post(
                endPoints.auth.checkForgetPasswordToken,
                data
            )
            return res
        } catch (error) {
            // console.error(
            //     'API_checkForgetPasswordToken',
            //     (error as any).response
            // )
            return (error as any).response
        }
    }
}

export const API_resetPassword = (data: {
    token: string
    password: string
    confirm_password: string
}) => {
    return async (_: Dispatch<any>) => {
        try {
            const res = await axios.post(endPoints.auth.resetPassword, data)
            return res
        } catch (error) {
            // console.error('API_resetPassword', (error as any).response)
            return (error as any).response
        }
    }
}

export const API_checkConfirmMailToken = (data: { token: string }) => {
    return async (_: Dispatch<any>) => {
        try {
            const res = await axios.post(
                endPoints.auth.checkConfirmMailToken,
                data
            )
            return res
        } catch (error: any) {
            // console.error('API_checkConfirmMailToken', error.response)
            return error.response
        }
    }
}
export const API_resendConfirmMail = (data: {
    username: string
    password: string
}) => {
    return async (_: Dispatch<any>) => {
        try {
            const res = await axios.post(endPoints.auth.resendConfirmMail, data)
            return res
        } catch (error) {
            // console.error('API_resendConfirmMail', (error as any).response)
            return (error as any).response
        }
    }
}
export const API_registerViaGoogle = (data: { token: string }) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const res = await axios.post(endPoints.auth.googleAuth, data)
            localStorage.setItem('token', res.data.token)
            dispatch(setToken(res.data.token))
            dispatch(setFirstName(res.data.first_name))
            dispatch(setLastName(res.data.last_name))
            dispatch(setIsUserLoggedIn(true))
            return res
        } catch (error: any) {
            // console.error('API_registerViaGoogle', error.response)
            return error.response
        }
    }
}

export const initUserCred = () => async (dispatch: Dispatch<any>) => {
    const token = localStorage.getItem('token')
    if (token && typeof token === 'string') {
        dispatch(setToken(token))
        const res = (await dispatch(
            API_getProfile()
        )) as unknown as AxiosResponse<any>
        if (res?.status === 200) {
            dispatch(setIsUserLoggedIn(true))
        } else {
            dispatch(setIsUserLoggedIn(false))
        }
    } else {
        dispatch(setIsUserLoggedIn(false))
    }
}
