const getIndicesOfBackslashes = (url: string) => {
    const indexes = []
    for (let index = 0; index < url.length; index++) {
        if (url[index] === '/') {
            indexes.push(index)
        }
    }
    return indexes
}

export const getNthEndpoint = (thePath: string, number: number) => {
    const indexes = getIndicesOfBackslashes(thePath)
    if (number == -1) {
        return thePath.substring(thePath.lastIndexOf('/') + 1)
    } else {
        return thePath.substring(
            indexes[number - 1] + 1,
            indexes[number - 1 + 1]
        )
    }
}
