import constants from '../constants'

let initialState = {
    landingContent: [],
    careerSearchTerm: '',
    sectors: [],
    companies: [],
    sectorsList: [],
    companiesList: [],
    activeCompany: {},
    news: {
        featNews: [],
        filteredNews: [],
        pages: 0,
        singleNews: {},
    },
    about: {},
    careers: [],
    careersNumOfPages: undefined,
    galleries: [],
    locations: [],
    resources: {
        all: [],
        pages: 0,
        single: {},
    },
    ActiveCompanyId: 2,
    isQuotaPosted: false,
    isErrorAtQuota: false,
    isContactUsPosted: false,
    isErrorAtContactUs: false,
}
const contentReducer = (
    state = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case constants.SET_LANDING_CONTENT:
            return {
                ...state,
                landingContent: action.payload,
            }
        case constants.SET_IS_CONTACT_US_POSTED:
            return {
                ...state,
                isContactUsPosted: action.payload,
            }
        case constants.SET_IS_ERROR_AT_CONTACT_US:
            return {
                ...state,
                isErrorAtContactUs: action.payload,
            }

        case constants.SET_IS_QUOTA_POSTED:
            return {
                ...state,
                isQuotaPosted: action.payload,
            }
        case constants.SET_IS_ERROR_AT_QUOTA:
            return {
                ...state,
                isErrorAtQuota: action.payload,
            }
        case constants.SET_ACTIVE_COMPANY_ID:
            return {
                ...state,
                ActiveCompanyId: action.payload,
            }
        case constants.SET_CAREER_NUM_OF_PAGES:
            return {
                ...state,
                careersNumOfPages: action.payload,
            }
        case constants.SET_CAREER_SEARCH_TERM:
            return {
                ...state,
                careerSearchTerm: action.payload,
            }
        case constants.SET_CAREER_SEARCH_TERM:
            return {
                ...state,
                careerSearchTerm: action.payload,
            }

        case constants.SET_CAREER_SEARCH_TERM:
            return {
                ...state,
                careerSearchTerm: action.payload,
            }
        case constants.SET_ACTIVE_COMPANY:
            return {
                ...state,
                activeCompany: action.payload,
            }

        case constants.SET_COMPANIES_LIST:
            return {
                ...state,
                companiesList: action.payload,
            }
        case constants.SET_FEAT_NEWS:
            return {
                ...state,
                news: { ...state.news, featNews: action.payload },
            }

        case constants.SET_ALL_NEWS:
            return {
                ...state,
                news: { ...state.news, filteredNews: action.payload },
            }
        case constants.SET_NUM_PAGES_NEWS:
            return {
                ...state,
                news: { ...state.news, pages: action.payload },
            }
        case constants.SET_SINGLE_NEWS:
            return {
                ...state,
                news: { ...state.news, singleNews: action.payload },
            }
        case constants.SET_RESOURCES:
            return {
                ...state,
                resources: { ...state.resources, all: action.payload },
            }

        case constants.SET_NUM_PAGES_RESOURCES:
            return {
                ...state,
                resources: { ...state.resources, pages: action.payload },
            }
        case constants.SET_SINGLE_RESOURCES:
            return {
                ...state,
                resources: { ...state.resources, single: action.payload },
            }

        case constants.SET_SECTORS:
            return {
                ...state,
                sectors: action.payload,
            }
        case constants.SET_ABOUT:
            return {
                ...state,
                about: action.payload,
            }
        case constants.SET_CAREERS:
            return {
                ...state,
                careers: action.payload,
            }
        case constants.SET_GALLERIES:
            return {
                ...state,
                galleries: action.payload,
            }
        case constants.SET_GENERAL_LOCATIONS:
            return {
                ...state,
                locations: action.payload,
            }
        default:
            return state
    }
}
export default contentReducer
