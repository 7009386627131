import arrowType2White from './images/arrowtype2.svg'
import arrowType2Dark from './images/arrowtype2-dark.svg'
import rightArrow from './images/down-arrow.svg'
import rightArrowGreen from './images/down-arrow-green.svg'
import rightArrowWhite from './images/down-arrow-white.svg'

import business from './images/icons/business.svg'
import poultry from './images/icons/poultry.svg'
import agriculture from './images/icons/agriculture.svg'
import brands from './images/icons/brands.svg'
import feed from './images/icons/feed.svg'
import hatcheries from './images/icons/farm-equipment.svg'

import feedHover from './images/icons/feed-hover.svg'
import brandsHover from './images/icons/brands-hover.svg'
import hatcheriesHover from './images/icons/farm-equipment-hover.svg'
import poultryHover from './images/icons/poultry-hover.svg'
import businessHover from './images/icons/business-hover.svg'
import agricultureHover from './images/icons/agriculture-hover.svg'

import feedActive from './images/icons/feed-active.svg'
import brandsActive from './images/icons/brands-active.svg'
import hatcheriesActive from './images/icons/farm-equipment-active.svg'
import poultryActive from './images/icons/poultry-active.svg'
import businessActive from './images/icons/business-active.svg'
import agricultureActive from './images/icons/agriculture-active.svg'

import poultrySectors from './images/sectors-icons/poultry.svg'
import businessSectors from './images/sectors-icons/business.svg'
import agricultureSectors from './images/sectors-icons/agriculture.svg'
import brandsSectors from './images/sectors-icons/brands.svg'
import feedSectors from './images/sectors-icons/feed.svg'
import hatcheriesSectors from './images/sectors-icons/farm-equipment.svg'

import poultrySectorsHover from './images/sectors-icons/poultry-hover.svg'
import businessSectorsHover from './images/sectors-icons/business-hover.svg'
import agricultureSectorsHover from './images/sectors-icons/agriculture-hover.svg'
import brandsSectorsHover from './images/sectors-icons/brands-hover.svg'
import feedSectorsHover from './images/sectors-icons/feed-hover.svg'
import hatcheriesSectorsHover from './images/sectors-icons/farm-equipment-hover.svg'

import cvIcon from './images/Cv icon.svg'
import search from './images/search.svg'
import crossMark from './images/close.svg'
import searchWhite from './images/searchWhite.svg'
import reload from './images/reload.svg'
import filterResultsIcon from './images/filter-results-button.svg'
import sortIcon from './images/sort.svg'
import facebook from './images/facebook.svg'
import linkedin from './images/linkedin.svg'
import twitter from './images/twitter.svg'
import whatsapp from './images/whatsapp.svg'
import circularArrowWhite from './images/circular-arrow-white.svg'
import circularArrowLeftGreen from './images/gallery-assets/circular-arrow-left-green.svg'
import circularArrowRightGreen from './images/gallery-assets/circular-arrow-right-green.svg'
import google from './images/google.svg'
import mail from './images/mail.svg'
import phone from './images/phone.svg'
import location from './images/location.svg'
import logoOpacity from './images/HG-png.png'
import poultrySmallIcon from './images/poultry-small-icon.svg'
import visionIcon from './images/vision-icon.svg'
import misionIcon from './images/mission-icon.svg'
import sectorIcon from './images/sector-icon.svg'
import factoriesIcon from './images/factories-icon.svg'
import companiesIcon from './images/companies-icon.svg'
import careersHero from './images/careers-hero.png'
import historyItemIndicator from './images/history-item.png'
import playIcon from './images/play-icon.svg'
import marker from './images/marker.svg'
import applicationSubmitted from './images/application-submitted.svg'

import transparentCeckMark from './images/check-mark-transparent.svg'
import greenCheckMark from './images/check-mark.svg'
import blackCheckMark from './images/black-check-mark.svg'
import bagIcon from './images/bag.svg'
import clockIcon from './images/clock.svg'
import locationGray from './images/locationGray.svg'
import bullet from './images/bullet.svg'
import arrowGreen from './images/arrow-green.svg'
import agricultureSmallIcon from './images/agricultureSmallIcon.svg'
import feedSmallIcon from './images/feedSmallIcon.svg'
import brandsSmallIcon from './images/Brands.svg'
import businessSmallIcon from './images/businessSmallIcon.svg'
import facebookFooter from './images/facebook-gray.svg'
import youTubeFooter from './images/youtube.svg'
import hatcheriesSmallIcon from './images/hatcheriesSmallIcon.svg'
import playVideo from './images/play.svg'
import pauseVideo from './images/pause.svg'
import jobs from './images/jobs.svg'
import errorIcon from './images/error.svg'
import plus from './images/plus.svg'
import pdf from './images/pdf-icon.svg'
import pdfLight from './images/pdf-light.svg'
import articleWhite from './images/article-white.svg'
import articleGray from './images/article-gray.svg'
import zoomIn from './images/gallery-assets/zoom-in.svg'
import zoomOut from './images/gallery-assets/zoom-out.svg'
import closeIcon from './images/gallery-assets/closeIcon.svg'
import emptyState from './images/empty.svg'
import defaultBg from './images/default-bg.png'
import article from './images/article.svg'
import video from './images/video.svg'
import passwordToggle from './images/password-toggle.svg'
import passwordToggleSlash from './images/password-toggle-slash.svg'
import avatar from './images/avatar.svg'
import logout from './images/logout.svg'
import briefAboutUs from './images/BriefAboutUs.svg'
import howWeGrewout from './images/HowWeGrewout.svg'
import visionMission from './images/Vision&Mission.svg'
import history from './images/History.svg'
import navResources from './images/navResources.svg'
import partners from './images/Partners.svg'
import certificates from './images/Certificates.svg'
import phoneWhite from './images/phone-white-icon.svg'
import locationWhite from './images/location-white-icon.svg'
import mailWhite from './images/mail-white-icon.svg'
import cameraIcon from './images/camera-icon.svg'
import unknownIcon from './images/unknown-img.svg'
import pdfIcon from './images/pdf.svg'
import editIcon from './images/Edit.svg'
import plusIcon from './images/plus.svg'
import deleteIcon from './images/Delete.svg'
import submitApp from './images/submit-application.svg'
import updateApp from './images/update-application.svg'
import borderActive from './images/border-active.svg'
import locationIcon from './images/location-icon.svg'
import accordionBottomArrow from './images/accordion-bottom-arrow.svg'
import remove from './images/Remove.svg'
import generator from './images/generators.png'
import locations from './images/locations.png'
import hiring from './images/hiring.svg'
import check from './images/check.svg'
import arrow from './images/circle-arrow-green.svg'

import googleIcon from './images/Group 5632.svg'

export default {
    hiring,
    googleIcon,
    check,
    marker,
    locations,
    phoneWhite,
    locationWhite,
    mailWhite,
    plus,
    cvIcon,
    pdfIcon,
    plusIcon,
    applicationSubmitted,
    remove,
    editIcon,
    cameraIcon,
    unknownIcon,
    borderActive,
    transparentCeckMark,
    errorIcon,
    bullet,
    clockIcon,
    historyItemIndicator,
    locationGray,
    sectorIcon,
    deleteIcon,
    blackCheckMark,
    playIcon,
    bagIcon,
    factoriesIcon,
    greenCheckMark,
    companiesIcon,
    visionIcon,
    misionIcon,
    poultrySmallIcon,
    logoOpacity,
    circularArrowWhite,
    crossMark,
    facebook,
    arrow,
    google,
    mail,
    phone,
    location,
    linkedin,
    twitter,
    whatsapp,
    rightArrowWhite,
    filterResultsIcon,
    sortIcon,
    rightArrow,
    rightArrowGreen,
    search,
    searchWhite,
    reload,

    careersHero,
    arrowGreen,
    agricultureSmallIcon,
    feedSmallIcon,
    brandsSmallIcon,
    businessSmallIcon,
    facebookFooter,
    youTubeFooter,
    hatcheriesSmallIcon,
    playVideo,
    pauseVideo,
    jobs,
    pdf,
    pdfLight,
    articleWhite,
    articleGray,
    emptyState,
    defaultBg,
    article,
    video,
    locationIcon,
    generator,
    arrowType2: {
        dark: arrowType2Dark,
        white: arrowType2White,
    },
    agricultureSectors: {
        normal: agricultureSectors,
        hover: agricultureSectorsHover,
    },
    brandsSectors: {
        normal: brandsSectors,
        hover: brandsSectorsHover,
    },
    feedSectors: {
        normal: feedSectors,
        hover: feedSectorsHover,
    },
    hatcheriesSectors: {
        normal: hatcheriesSectors,
        hover: hatcheriesSectorsHover,
    },
    poultrySectors: {
        normal: poultrySectors,
        hover: poultrySectorsHover,
    },
    businessSectors: {
        normal: businessSectors,
        hover: businessSectorsHover,
    },

    agriculture: {
        normal: agriculture,
        hover: agricultureHover,
        active: agricultureActive,
    },
    brands: {
        normal: brands,
        hover: brandsHover,
        active: brandsActive,
    },
    'feed-feed-additives': {
        normal: feed,
        hover: feedHover,
        active: feedActive,
    },
    'hatcheries-farm-equipment': {
        normal: hatcheries,
        hover: hatcheriesHover,
        active: hatcheriesActive,
    },
    poultry: {
        normal: poultry,
        hover: poultryHover,
        active: poultryActive,
    },
    'business-solutions': {
        normal: business,
        hover: businessHover,
        active: businessActive,
    },
    circularArrowRightGreen,
    circularArrowLeftGreen,
    zoomIn,
    zoomOut,
    closeIcon,
    passwordToggle,
    passwordToggleSlash,
    avatar,
    logout,

    briefAboutUs,
    howWeGrewout,
    visionMission,
    history,
    navResources,
    partners,
    certificates,
    submitApp,
    updateApp,
    accordionBottomArrow,
}
