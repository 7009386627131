import React, { useEffect, useRef, useState } from 'react'
import NavListItem from './NavListItem'
import { useTranslation } from 'react-i18next'
import { NavDropdown } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setActiveCompanyId } from 'store/actions/contentActions'

interface Props {
    title: any
    data: any
    endpoint: string
    isCompany?: boolean
    isAbout?: boolean
    hideToggle: () => void
    width?: any
}

const CustomDropdown: React.FC<Props> = (props) => {
    const location = useLocation()
    const { t } = useTranslation()
    const navRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    useEffect(() => {
        if ((props.title, location.pathname.includes(props.endpoint))) {
            ;(navRef.current?.childNodes[0] as any).classList.add('active')
        } else {
            ;(navRef.current?.childNodes[0] as any).classList.remove('active')
        }
    }, [location])
    const onClick = (item: any) => {
        if (props.isCompany) {
            dispatch(setActiveCompanyId(item.id))
        }
        props.width < 992 && props.hideToggle()
    }

    return (
        <NavDropdown ref={navRef} title={t(props.title)}>
            {props.data.map((item: any, index: any) => (
                <NavListItem
                    key={index}
                    item={item}
                    isAbout={props.isAbout}
                    onClick={onClick.bind(null, item)}
                    isCompany={props.isCompany}
                />
            ))}
        </NavDropdown>
    )
}

export default CustomDropdown
