import React from 'react'
import { useTranslation } from 'react-i18next'
import images from '../../assets/images'
import { siteMapData } from '../../content'
import CopyRight from './CopyRight'
import { useSelector } from 'react-redux'
import { RootState } from 'store/configStore'
import { NavLink } from 'react-router-dom'
import { ApiSectorState } from 'types/sector.modal'

const SectionFooter: React.FC = (props) => {
    const { t } = useTranslation()
    const comapniesList = useSelector(
        (state: RootState) => state.contentReducer.companiesList
    )
    const { sectors } = useSelector((state: RootState) => state.contentReducer)

    return (
        <footer>
            <div className="column1">
                <div key={'first'}>
                    <div className="logo-with-title">
                        <img
                            src="https://hegazy-website.s3.eu-west-2.amazonaws.com/media/static/logo.png"
                            alt="HG Logo"
                        />
                        <h4>{t('HG Group')}</h4>
                    </div>

                    <p>
                        {t(
                            'HG Group is a full-fledged integrated agricultural establishment, with strong leadership at every step of the industry value chain'
                        )}
                    </p>
                </div>
                <div className="column1__list-wrapper col-md-8">
                    {siteMapData.map(({ title, items }, index) => {
                        if (index < 1)
                            return (
                                <ul key={title} className="list-inner">
                                    <li>{t(title)}</li>
                                    {items.map(({ title, to }) => (
                                        <li key={title}>
                                            <a href={to}>{t(title)}</a>
                                        </li>
                                    ))}
                                </ul>
                            )
                    })}

                    <ul className="list-inner">
                        <li>{t('Companies')}</li>
                        {comapniesList.map((item: any) => (
                            <li key={`companies-${item.id}`}>
                                <NavLink to={`/companies/${item.id}`}>
                                    {t(item.title)}
                                </NavLink>
                            </li>
                        ))}
                    </ul>

                    <ul className="list-inner">
                        <li>{t('Sectors')}</li>
                        {sectors.map((item: ApiSectorState) => (
                            <li key={item.id}>
                                <NavLink to={`/sectors?sector=${item.id}`}>
                                    {t(item.title)}
                                </NavLink>
                            </li>
                        ))}
                    </ul>

                    <div className="last list-inner" key="last">
                        {siteMapData.map(({ title, items }, index) => {
                            if (index >= 3)
                                return (
                                    <ul key={title + index}>
                                        <li>{t(title)}</li>
                                        {items.map(({ title, to }) => (
                                            <li key={title}>
                                                <a href={to}>{t(title)}</a>
                                            </li>
                                        ))}
                                    </ul>
                                )
                        })}
                    </div>
                </div>
            </div>

            {/* <div className="privacy-policy">
                <a>
                    <span>{t('Privacy & Policy')}</span>
                </a>
                <a>
                    <img src={images.youTubeFooter} />
                </a>
                <a>
                    <img src={images.facebookFooter} />
                </a>
            </div> */}
            <CopyRight />
        </footer>
    )
}

export default SectionFooter
