export default {
    translation: {
        'Copyrights Reserved C HG Group 2022':
            'Copyrights Reserved C HG Group 2022',
        Poultry: 'دواجن',
        'Hatcheries & Farm Equipment': 'المفارخ ومعدات المزارع',
        'Feed & Feed Additives': 'إضافات الأعلاف والأعلاف',
        Agriculture: 'زراعة',
        Brands: 'العلامات التجارية',
        'Business Solutions': 'حلول الأعمال',
        'Share Article': 'شارك المقال',
        'Read More': 'اقرأ المزيد',
        'Latest News': 'أخر الأخبار',
        العربية: 'English',
        ع: 'En',

        'About Us': 'من نحن',
        Companies: 'الشركات',
        Sectors: 'القطاعات',
        News: 'الاخبار',
        Resources: 'الموارد',
        Gallery: 'المعرض',
        Careers: 'الوظائف',
        'Contact us': 'اتصل بنا',
        'Head Office': 'المقر الرئيسي',
        'Tanta Office': 'مقر طنطا',
        'Contact Us': 'اتصل بنا',
        'Do You Have Any Question, How Can we Help You?':
            'هل لديك سؤال كيف يمكننا مساعدتك؟',
        'Please get in touch and our support team will answer all your questions':
            'يرجى التواصل معنا وسيقوم فريق الدعم بالإجابة على جميع أسئلتك',
        'Our Headquarters': 'المقرات الرئيسية لدينا',
        '94 Al Tahrir Street, Blaza Tower - 3rd floor, Cairo-Giza':
            '94 شارع التحرير - برج البلازا - الدور الثالث - القاهرة - الجيزة',
        '10 Moawia street, Tanta, Egypt': '10 شارع معاوية ، طنطا ، مصر',
        'First Name *': 'الإسم الأول *',
        'Last Name *': 'الإسم الأخير *',
        'Last Name': 'الإسم الأخير',
        'First Name': 'الإسم الأول',
        'Mobile Number': 'رقم التليفون',

        'Email Address *': 'الإيميل *',
        'Mobile Number *': 'رقم التليفون *',
        'Your Message *': 'رسالتك *',
        Submit: 'إرسال',
        'HG Group is a full-fledged integrated agricultural establishment, with strong leadership at every step of the industry value chain':
            'مجموعة HG هي مؤسسة زراعية متكاملة ، تتمتع بقيادة قوية في كل خطوة من سلسلة القيمة الصناعية',
        'Contact Form': 'استمارة التواصل',
        'Featured News': 'أهم الأخبار',
        'Further Readings': 'أخبار ذات صلة',
        About: 'عن الشركة',
        'Reach Us': 'تواصل معنا',
        'Join Us': 'انضم إلينا',
        'Over 45 Years of Great Impact': 'أكثر من 45 عامًا من التأثير العظيم',
        'HG Group’s History in the Egyptian Market':
            'تاريخ HG Group في السوق المصري',
        'Learn More about our Expansions within the Industry':
            'تعرف على المزيد حول توسعاتنا في الصناعة',
        'Superiority in Agricultural Manufacturing Practices':
            'التفوق في ممارسات التصنيع الزراعي',
        'Sustainable Agricultural Products': 'المنتجات الزراعية المستدامة',
        'Venture into the Large Animals Sector':
            'المغامرة في قطاع الحيوانات الكبيرة',
        'In 2011, HG Group further explored agricultural manufacturing primarily through olive oil products. We proudly produce olive oil that is at the highest of global standards and that are all extra virgin. Additionally, we initiated our food processing plant to produce convenient salads and ready- made meals, prepared utilizing the latest technologies and hygiene techniques':
            'في عام 2011 ، قامت مجموعة HG باستكشاف التصنيع الزراعي بشكل أساسي من خلال منتجات زيت الزيتون. نفخر بإنتاج زيت زيتون بأعلى المعايير العالمية وبكر ممتاز. بالإضافة إلى ذلك ، بدأنا مصنع تجهيز الأغذية الخاص بنا لإنتاج سلطات مناسبة ووجبات جاهزة ، معدة باستخدام أحدث التقنيات وتقنيات النظافة',
        'It is HG Group’s underlying philosophy to produce safe and superior quality food. Therefore, in 1997, HG Group penetrated the agricultural sector with over 8,000 acres of land diversified between conventional and organic agriculture to expand into fruits, vegetables, and crops production. HG Group is proudly environmentally-conscious and we continuously compost our waste to be recycled.':
            'إن الفلسفة الأساسية لمجموعة HG هي إنتاج طعام آمن وعالي الجودة. لذلك ، في عام 1997 ، توغلت مجموعة HG في القطاع الزراعي مع أكثر من 8000 فدان من الأراضي المتنوعة بين الزراعة التقليدية والعضوية للتوسع في إنتاج الفواكه والخضروات والمحاصيل. تفخر HG Group بالوعي البيئي ونعمل باستمرار على تحويل نفاياتنا إلى سماد لإعادة تدويرها.',
        'In 2008, HG Group ventured into the Large Animals sector, namely beef and dairy. With the intent to continuously focus on quality, taste, and innovation, our consumers benefit from our standards in quality of our fresh meat, pasteurized milk, and milk products':
            'في عام 2008 ، دخلت مجموعة HG في قطاع الحيوانات الكبيرة ، أي لحوم البقر ومنتجات الألبان. بقصد التركيز المستمر على الجودة والذوق والابتكار ، يستفيد عملاؤنا من معاييرنا في جودة اللحوم الطازجة والحليب المبستر ومنتجات الألبان.',
        'Established in 1981, HG Group initially focused business operations in the Egyptian poultry industry as a prominent grower of broiler hens. HG Group further expanded within the market, capturing unmet needs and thereby supplying the market with high quality and reliable supplies including feeds, day-old chicks (parent stock, commercial layer, and commercial broiler), veterinarian medicine, hatcheries, farm equipment, and much more':
            'تأسست عام 1981 ، ركزت HG Group مجالات أعمالها منذ نشأتها في صناعة الدواجن بالسوق المصري كمربي بارز لدجاج التسمين. توسعت HG Group بشكل أكبر داخل السوق ، لتلبي الاحتياجات غير الملباة وذلك بتزويد السوق بإمدادات عالية الجودة جديرة بالثقة بما في ذلك الأعلاف ، والكتاكيت عمر يوم (الأمهات ، والبياض ، ودجاج التسمين) ، والأدوية البيطرية ، ومعامل التفريخ، ومعدات المزارع ، و أكثر من ذلك بكثير.',
        'Vision & Mission': 'الرؤية والرسالة',
        'Our Mission': 'رسالتنا',
        'Our Vision': 'رؤيتنا',
        'We care to make a significant and lasting impact to our people and community’s wellbeing by inspiring their everyday meals':
            'نحن نهتم بإحداث تأثير كبير ودائم على رفاهية موظفينا ومجتمعنا من خلال إلهام وجباتهم اليومية',
        'From Good Roots, we bring quality, inspiration and innovation to Good Food, it begins with our people, our state-of-the-art facilities, and our exceptional products to ultimately nourish the human senses and spirit, while embracing the environment':
            'من الجذور الجيدة ، نجلب الجودة والإلهام والابتكار إلى الطعام الجيد ، يبدأ الأمر بموظفينا ومرافقنا الحديثة ومنتجاتنا الاستثنائية لتغذية حواس الإنسان وروحه في نهاية المطاف ، مع احتضان البيئة',
        'HG Group is now Leading the Industry':
            'HG Group هي الرائدة الآن في الصناعة',
        'Since establishment, HG Group has heavily invested in all production facilities towards full automation, modern integration, and building a seamless value chain ecosystem. HG Group’s technological capabilities and exceptional agricultural solutions are at the forefront of our success.':
            'منذ التأسيس ، استثمرت مجموعة HG بشكل كبير في جميع مرافق الإنتاج نحو الأتمتة الكاملة والتكامل الحديث وبناء نظام بيئي سلس لسلسلة القيمة. القدرات التكنولوجية والحلول الزراعية الاستثنائية لمجموعة HG هي في طليعة نجاحنا.',
        Factories: 'المصانع',
        'Deep-Dive into our Journey of Success': 'تعمق في رحلة نجاحنا',
        'View More': 'عرض المزيد',
        'Read More in our Sectors': 'اقرأ المزيد في قطاعاتنا',
        'HG Group is the Sole Agent for some of the Leading Companies Globally':
            'HG Group هي الوكيل الوحيد لبعض الشركات الرائدة على مستوى العالم',
        'One-Day Old Layer Chick': 'كتكوت طبقة عمره يوم واحد',
        'Chicks Per Year': 'كتاكيت سنويا',
        'Ton premixes Per Year': 'طن بريمكسس في السنة',
        'Acres of Diverse Land': 'فدان من الأراضي المتنوعة',
        'Quality is a core pillar of HG Group and we are continuously upgrading the standards of the market by ensuring our consumers are provided with food that is consistently safe and hygienic, and truly differentiated in the market.':
            'الجودة هي الركيزة الأساسية لمجموعة HG ونحن نعمل باستمرار على ترقية معايير السوق من خلال ضمان تزويد عملائنا بأغذية آمنة وصحية باستمرار ، ومتميزة حقًا في السوق.',
        Certificates: 'الشهادات',
        'Filter By: ': 'تصنيف بواسطة: ',
        'Filter By: Sectors': 'تصنيف بحسب: القطاعات',
        'Sort By: ': 'ترتيب حسب: ',
        'Explore New Career Opportunities': 'اكتشف فرص عمل جديدة',
        "Let's Work Together": 'لنعمل معا',
        Reset: 'مسح',
        'Specializing in Producing High Quality Industry Inputs':
            'متخصصة في إنتاج مدخلات الصناعة عالية الجودة',
        'We started in the Egyptian market in 1981 as grower of broiler hens':
            'بدأنا في السوق المصري عام 1981 كمزارع لدجاج التسمين',
        'Our birds are raised in a conducive environment':
            'تربى طيورنا في بيئة ملائمة',
        'with a focus on biosafety and biosecurity ensuring healthy meat':
            'مع التركيز على السلامة البيولوجية والأمن البيولوجي لضمان صحة اللحوم',
        'The equipment in your hatchery plays a crucial role':
            'تلعب المعدات الموجودة في المفرخ دورًا مهمًا',
        'when it comes to an efficient hatchery workflow':
            'عندما يتعلق الأمر بسير عمل فعال في المفرخات',
        'Newest First': 'الأحدث أولاً',
        'Oldest First': 'الأقدم أولا',
        'Search about jobs': 'ابحث عن الوظائف',
        'Privacy & Policy': 'الخصوصية والأمان',
        'Read in All Sectors': 'اقرأ في جميع القطاعات',
        'Read in': 'اقرأ في',
        'Search about article, pdf, video': 'البحث عن مقال ، ملف ، فيديو',
        'Must be 6-22 characters': 'يجب أن يكون من 6 إلى 22 حرفًا',
        'At least one number': 'رقم واحد على الأقل',
        'At least one caital letter': 'حرف كبير واحد على الأقل',
        'Our Sectors': ' قطاعاتنا',
        History: 'تاريخنا',
        Partners: 'الشركاء',
        'Brief About Us': 'نبذة عنا',
        'How We Grew Out': 'توسعاتنا',
        'No Open Positions Available': 'لا توجد وظائف شاغرة',
        'Thank you for your interest in Hegazy Group jobs! You can start your application and updating continuously to help us find you as a candidate for later open positions':
            'شكرا لاهتمامك بوظائف مجموعة حجازي! يمكنك بدء طلبك والتحديث باستمرار لمساعدتنا في العثور عليك كمرشح للمناصب المفتوحة في وقت لاحق',
        'Apply Now': 'قدم الآن',
        'Photos Speak a Thousand Words': 'الصور تتحدث بألف كلمة',
        'Discover Our Gallery Collections': 'اكتشف مجموعات معرض الصور لدينا',
        'Our Companies': 'شركاتنا',
        Departments: 'الاقسام',
        'Job Types': 'أنواع الوظائف',
        'other questions': 'اسئلة اخرى',
        'Other Questions*': 'اسئلة اخرى*',
        '1. Have you ever worked with us in the company?':
            '1. هل سبق لك العمل معنا في الشركة؟',
        '2. Do you have relatives, acquaintances or friends who work in the company?':
            '2. هل لديك أقارب أو معارف أو أصدقاء يعملون في الشركة؟',
        '3. Are you ready to work outside your city of residence?':
            '3. هل أنت مستعد للعمل خارج مدينتك التي تقيم فيها؟',
        '4. Do you have any chronic diseases or have you had an operation?':
            '4. هل لديك أي أمراض مزمنة أو أجريت لك عملية جراحية؟',
        '5. Are you already insured in your previous job?':
            '5. هل أنت مؤمن بالفعل في وظيفتك السابقة؟',
        Yes: 'نعم',
        No: 'لا',
        'Save Changes': 'حفظ التغييرات',
        Profile: 'الملف الشخصي',
        'Thank you for your interest in Hegazy Group jobs!':
            'شكرا لاهتمامك بوظائف مجموعة حجازي!',
        'Please fill your application requirements and you can updating continuously. Reviewers of your application will be able to see any information you update here. You can also use this profile as a way to stay interested in future opportunities at Hegazy Group.':
            'يرجى ملء متطلبات التطبيق الخاص بك ويمكنك التحديث باستمرار. سيتمكن المراجعون لتطبيقك من رؤية أي معلومات تقوم بتحديثها هنا. يمكنك أيضًا استخدام هذا الملف الشخصي كطريقة للبقاء مهتمًا بالفرص المستقبلية في مجموعة حجازي.',
        'What Job Department are you interested in?':
            'ما هو قسم الوظائف الذي تهتم به؟',
        'What are the job titles that describe what you are looking for?':
            'ما هي المسميات الوظيفية التي تصف ما تبحث عنه؟',
        'career interest': 'الاهتمام الوظيفى',
        'upload CV': 'رفع السيرة الذاتية',
        cv: 'رفع السيرة الذاتية',

        'general information': 'معلومات عامة',
        education: 'تعليم',
        experience: 'خبرات',
        'skills & languages': 'المهارات واللغات',
        'skills and languages': 'المهارات واللغات',
        'training courses': 'دورات تدريبية',
        'personal information': 'معلومات شخصية',
        'Marital Status*': 'الحالة الإجتماعية*',
        'Do you have Driving License ?*': '*هل لديك رخصة قيادة؟',
        'Gender*': 'جنس*',
        'No available resources found': 'لا توجد موارد متاحة',
        'You can upload a .jpg, .png, or .gif photo with max size of 5MB.':
            'يمكنك تحميل صورة بتنسيق .jpg أو .png أو .gif بحجم أقصى يبلغ 5 ميغابايت.',
        'Last Name can be in english or arabic letters only':
            'يمكن كتابة اسم العائلة بالحروف الإنجليزية أو العربية فقط',
        'First Name can be in english or arabic letters only':
            'يمكن كتابة الاسم الأول بالحروف الإنجليزية أو العربية فقط',
        'First Name*': 'الاسم الاول*',
        'Last Name*': 'اسم العائلة*',
        'Birthday*': 'تاريخ الميلاد*',
        'Nationality*': 'جنسية*',
        'Military Status': 'الحالة العسكرية',
        Female: 'انثى',
        Male: 'ذكر',
        Married: 'متزوج',
        Single: 'اعزب',
        'Married & have children': 'متزوج ولدي أطفال',
        Other: 'آخر',
        'Children Number': 'عدد الاطفال',
        'Upload your CV': 'قم برفع سيرتك الذاتية',
        'Supported Files:': 'الملفات المدعومة:',
        'with maximum size of 5 MB': 'بحد أقصى 5 ميغا بايت',
        'Upload your photo': 'قم برفع صورتك',
        Location: 'الموقع',
        'Contact Information': 'معلومات التواصل',
        'Government*': 'المحافظة*',
        'City/Center*': 'مدينة*',
        'Town/Village*': 'بلدة/قرية*',
        'Alternative Number': 'رقم هاتف بديل',
        'Mobile Number*': 'رقم الهاتف*',
        'Add Education': 'أضف مرحلة تعليمية',
        'Remove Language': 'إزالة اللغة',
        'Remove Education': 'ازالة مرحلة تعليمية',
        'Education Level*': 'مرحلة تعليمية*',
        'Specialisation*': 'التخصص*',
        'University/Institute*': 'جامعة / معهد*',
        'Grade*': 'التقدير*',
        'Start Month*': 'شهر البدء *',
        'Start Year*': 'سنة البدء*',
        'End Month*': 'شهر النهاية*',
        'End Year*': 'سنة النهاية*',
        'Resource Type: ': 'النوع: ',
        All: 'الكل',
        video: 'فيديو',
        article: 'مقال',
        pdf: 'ملف',
        'Discover Our Sectors': 'اكتشف قطاعاتنا',
        'Submitted Applications': 'الطلبات المقدمة',
        Logout: 'تسجيل الخروج',
        'Back to Careers': 'العودة إلي الوظائف',
        'Sign in': 'تسجيل الدخول',
        'To apply for our career opportunities': 'للتقدم لفرص وظيفية لدينا',
        'Email Address': 'الإيميل',
        'Forgot Password?': 'هل نسيت كلمة السر؟',
        'Please enter a valid email address':
            'يرجى إدخال عنوان بريد إلكتروني صالح',
        Password: 'كلمة السر',
        "Don't have an account yet?": 'لا تملك حسابا حتى الآن؟',
        'Create Account': 'إنشاء حساب',
        'Already have account?': 'لديك حساب بالفعل؟',
        'Add Experience': 'أضف خبرة',
        'Currently working in this position': 'تعمل حاليا في هذا المنصب',
        'Remove Experience': 'إزالة الخبرة',
        'Company/Organization*': 'الشركة / المنظمة *',
        'Experience Type*': 'نوع الخبرة *',
        'Job Title*': 'مسمى وظيفي*',
        Salary: 'مرتب',
        'Describe your job tasks and roles': 'صِف مهامك وأدوارك الوظيفية',
        'Add Language': 'اضف لغة',
        'Add skill': 'إضافة مهارة',
        Skills: 'المهارات',
        'Language*': 'لغة*',
        'Proficiency*': 'الكفاءة *',
        Languages: 'اللغات',
        'Course Name*': 'اسم الدورة التدريبية*',
        'Add Training Course': 'اضافة دورة تدريبية',
        'Training Center': 'مركز تدريب',
        Description: 'التفاصيل',
        'Apply to': 'التقديم على',
        'Job Description': 'الوصف الوظيفي',
        'Job Requirements': 'متطلبات الوظيفة',
        'Request For Quotation': 'طلب عرض أسعار',
        'Documents Can Be Customized to Fit Your Business’s Needs':
            'يمكن تخصيص المستندات لتناسب احتياجات عملك',
        'Will Provide a document that details a buyer’s requirements.':
            'سوف نقدم وثيقة توضح متطلبات المشتري.',
        'RFQ Document': 'طلب وثيقة عرض أسعار',
        'Know More about our Sectors & Products':
            'تعرف على المزيد عن قطاعاتنا ومنتجاتنا',
        'What are our Main Specialties?': 'ما هي تخصصاتنا الرئيسية؟',
        'Send out RFQ': 'أرسل طلب عرض الأسعار',
        'How we Impact in Egyptian Market': 'كيف نؤثر في السوق المصري',
        Locations: 'مواقعنا',
        'Awarded to': 'منحت إلى',
        'We are the Destination for our Partners Products':
            'نحن الوكيل لمنتجات شركائنا',

        Intern: 'تدريب',
        'Part-time': 'دوام جزئى',
        'Full-time': 'دوام كامل',
        Files: 'عدد الملفات',
        'Remove Training course': 'حذف دورة تدريبية',
        "I don't have courses": 'ليس لدي دورات',
        'if you need to add a skill, type your skill then press enter':
            'إذا كنت بحاجة إلى إضافة مهارة ، فاكتب المهارة الخاصة بك ثم اضغط على إدخال',
        'Issued at': 'تم إصدارها في',
        'Copyrights Reserved HG Group 2022':
            'حقوق النشر محفوظة مجموعة اتش جي ٢٠٢٢',
        'Reset Password': 'إعادة تعيين كلمة المرور',
        'New Password': 'كلمة السر الجديدة',
        'Resend Activation Mail': 'إعادة إرسال بريد التفعيل',
        'Back to': 'العودة إلي',
        Change: 'تغيير',
        'Check the email sent for you to activate your account':
            'تحقق من البريد الإلكتروني المرسل لك لتفعيل حسابك',
        'Your Request were sent Successfully': 'تم إرسال طلبك بنجاح',
        "There's an error please try to submit again":
            'هناك خطأ يرجى محاولة الإرسال مرة أخرى',
        "There's an error please try to reset your password again":
            'هناك خطأ يرجى محاولة إعادة تعيين كلمة المرور الخاصة بك مرة أخرى',
        'Check the email sent for you to reset your password':
            'تحقق من البريد الإلكتروني المرسل لك لإعادة تعيين كلمة المرور الخاصة بك',
        'Our Big Achievements': 'إنجازاتنا الكبرى',
        'We provide insightful educational resources that help you to enhance your business quality':
            'نحن نقدم موارد تعليمية ثاقبة تساعدك على تحسين جودة عملك',
        'Published at': 'تم النشر في',
        "There's No Furtehr Readings": 'لا توجد قراءات أخرى',
        'The Sector Include': 'يشمل القطاع',
        'Specialized Companies': 'الشركات المتخصصة',
        'HG Group': 'مجموعة HG',
        'Join Now': 'انضم الان',
        Collections: 'معرض',
        'Your email or password is incorrect, please try again':
            'البريد الإلكتروني أو كلمة المرور الخاصة بك غير صحيحة ، يرجى المحاولة مرة أخرى',
        'Sign in with Google': 'سجّل الدخول باستخدام Google',
        or: 'أو',
        'Please enter a valid phone number': 'الرجاء إدخال رقم هاتف صحيح',
        'Asks vendors to respond with pricing and payment terms.':
            'يطلب من البائعين الرد مع تحديد الأسعار وشروط الدفع.',
        Products: 'المنتجات',
        'Learn More': 'أعرف أكثر',
        Student: 'طالب',
        'Entry Level': 'مبتدئ الخبرة',
        Manager: 'مدير',
        Sending: 'إرسال',
        'Driving License Type*': 'نوع رخصة القيادة *',
        Private: 'خاصه',
        First: 'أولي',
        Second: 'ثانيه',
        Third: 'ثالثه',
        'Your account has been successfully created': 'تم إنشاء حسابك بنجاح',
        'Email already registered': 'البريد الإلكتروني مسجل مسبقا',
        'Your account has not been activated': 'لم يتم تفعيل حسابك بعد',
        'Please enter your email, and password to send activation link again':
            'الرجاء إدخال البريد الإلكتروني وكلمة المرور لإرسال رابط التفعيل مرة أخرى',
        'Not Applicable': 'لا ينطبق',
        Exempted: 'معفى',
        Completed: 'تم الانتهاء منها',
        Postponed: 'مؤجل',
        'you are already applied in that career before':
            'لقد تم التقديم علي هذه الوظيفة من قبل',
        'Create an Account': 'إنشاء حساب',
        'Confirm Password': 'تأكيد كلمة السر',
        'Your password Has been reset successfully':
            'تم إعادة تعيين كلمة السر الخاصة بك بنجاح',
        "I don't have experience yet": 'ليس لدي خبرة بعد',
        'Type their names*': 'اكتب أسمائهم *',
        'Type your favourite locations*': 'اكتب الأماكن المفضلة لديك *',
        'Disease name/operation*': 'اسم المرض / العملية *',
        'Company Name*': 'اسم الشركة*',
        'No Submitted Applications Yet': 'لم يتم تقديم أي طلبات حتى الآن',
        'At least one capital letter': 'حرف كبير واحد على الأقل',
        "Arabic letters aren't allowed": 'لا يسمح باستخدام الحروف العربية',
        "Passowrd doesn't match": 'كلمة المرور غير متطابقة',
        'Location*': 'الموقع*',
        'Tell us why you leave this position?*':
            'أخبرنا لماذا تركت هذا المنصب؟ *',
        'Last Name can be in english only':
            'يمكن كتابة اسم العائلة بالحروف الإنجليزية فقط',
        'First Name can be in english only':
            'يمكن كتابة الاسم الأول بالحروف الإنجليزية فقط',
        'Country*': 'الدولة*',
        'Experienced (Non Manager)': 'من ذوي الخبرة (غير مدير)',
        'Senior Mangagment (e.g. VP, CEO)':
            'كبار المديرين (مثل نائب الرئيس والرئيس التنفيذي)',
        'High School': 'المدرسة الثانوية',
        'Bachelor’s Degree': 'درجة البكالوريوس',
        'Master’s Degree': 'درجة الماجستير',
        'Doctorate Degree': 'درجة الدكتوراه',
        Vocational: 'مهني',
        'Technical Diploma': 'دبلوم فني',

        'A / Excellent / 85-100%': 'أ / ممتاز / ٨٥-١٠٠٪',
        'B / Very Good / 75-85%': 'ب / جيد جدًا / ٧٥-٨٥٪',
        'C / Good / 65-75%': 'ج / جيد / ٦٥-٧٥٪',
        'D / Fair / 50-65%': 'د / مقبول / ٥٠-٦٥٪',
        Beginner: 'مبتدئ',
        Intermediate: 'متوسط',
        Advanced: 'متقدم',
        Fluent: 'فصيح',
        'Application created successfully': 'تم التقديم علي الوظيفه بنجاح',
        'No available news found': 'لا توجد أخبار متوفرة',
        'End date can’t be earlier than start date':
            'يجب ألا يكون تاريخ الانتهاء قبل تاريخ البدء',
        only: 'فقط',
        "Email doesn't exist": 'البريد الإلكتروني غير موجود',
        "If you're still studying, add your expected graduation year.":
            'إذا كنت لا تزال تدرس ، فقم بإضافة سنة التخرج المتوقعة.',
        'Designed & Developed by': 'تصميم و تنفيذ',
        General: 'اخبار عامة',
    },
}
